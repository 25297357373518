import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import applicationTypeService from "../applicationSelectService";

const initialState = {
    series: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Type cars
export const applicationsSeries = createAsyncThunk(
    "applications/applicationsSeries",
    async ({year, modelsId}, thunkAPI) => {
        try {
            return await applicationTypeService.appSeries(year, modelsId);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const applicationSeriesSlice = createSlice({
    name: "applicationsSeries",
    initialState,
    reducers: {
        seriesReset: (state) => {
            state.series = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(applicationsSeries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(applicationsSeries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.series = action.payload;
            })
            .addCase(applicationsSeries.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.series = null;
            })
    },
});
export const {seriesReset} = applicationSeriesSlice.actions;

export default applicationSeriesSlice.reducer;
