import React, {useRef, useState} from "react";
import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {applicationFilterData} from "../../../../store/applications/application-filter/applicationFilterSlice";
import {applicationsAll} from "../../../../store/applications/applicationsSlice";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import {
    selectArray,
    selectArrayParking, selectArraySorting,
} from "../../../../utils/select-array";
import {useCallback} from "react";

function ApplicationSearch({sessionFilter, setFilterHome, filterHome, currentPage}) {
    const {filter} = useSelector((state) => state.applicationFilter)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchRef = useRef(null)
    const partnerRef = useRef(null)
    const parkingRef = useRef(null)

    const partnerSelect = selectArray(filter?.partners, "partner")
    const parkingsSelect = selectArrayParking(filter?.parkings, "parking")
    const filterSelect = selectArraySorting(filter?.sortByChatAndService, "sort_by")

    const [partnerList, setPartnerList] = useState(null)
    const [parkingList, setParkingList] = useState(null)
    const [sortByList, setSortByList] = useState(null)

    const [textValue, setTextValue] = useState(null)
    const [partnerValue, setPartnerValue] = useState(null)
    const [parkingValue, setParkingValue] = useState(null)
    const [sortByValue, setSortByValue] = useState(null)


    useEffect(() => {
        dispatch(applicationFilterData()).then((response) => {
            if (response.payload === undefined) {
                localStorage.removeItem("web_vinz");
            }
        });
    }, [])

    //сохраняем данные локально, что бы добавить возможность выбирать пустое значение в select
    useEffect(() => {
        setPartnerList([{
            value: "",
            label: "Выберите заказчика",
            name: "partner",
        }, ...partnerSelect])

        setParkingList([{
            value: "",
            label: "Выберите стоянку",
            name: "parking",
        }, ...parkingsSelect])

        setSortByList([{
            value: "",
            label: "Сортировать",
            name: "sortBy",
        }, ...filterSelect])
    }, [filter])

    useEffect(() => {
        if (filterHome.length !== 0) {
            localStorage.setItem("filter", JSON.stringify(filterHome));
        }
    }, [textValue, partnerValue, parkingValue, sortByValue])

    useEffect(()=>{
        const tst =  JSON.parse(localStorage.getItem("filter"))
        if (tst?.search) {
            setTextValue(tst.search)
            setFilterHome((prevFilterHome) => ({
                ...prevFilterHome,
               'search': tst.search,
            }))
        }
        if (tst?.partner){
            setPartnerValue(partnerSelect.find((item) => item.value === tst.partner))
            setFilterHome((prevFilterHome) => ({
                ...prevFilterHome,
                'partner': tst.partner,
            }))
        }
        if (tst?.parking){
            setParkingValue(parkingsSelect.find((item) => item.value === tst.parking))
            setFilterHome((prevFilterHome) => ({
                ...prevFilterHome,
                'parking': tst.parking,
            }))
        }
        if (tst?.sort_by){
            setSortByValue(filterSelect.find((item) => item.value === tst.sort_by))
            setFilterHome((prevFilterHome) => ({
                ...prevFilterHome,
                'sort_by': tst.sort_by,
            }))
        }
    }, [filter])

    const filterFunction = (e) => {
        const {name, value, label, type, checked} = e.target ? e.target : e
        if (name === undefined || name === 'search') {
            e.preventDefault()
        }
        let newValue = value;
        if (type === "checkbox") {
            newValue = checked ? 1 : 0
        }
        if (name === "search") {
            setTextValue({
                value: newValue,
            })
        }
        if (name === "partner") {
            setPartnerValue({
                value: newValue,
                label: label,
                name: "partner",
            })
        }
        if (name === "parking") {
            setParkingValue({
                value: newValue,
                label: label,
                name: "parking",
            })
        }
        if (name === "sort_by") {
            setSortByValue({
                value: newValue,
                label: label,
                name: "sort_by",
            })
        }
        setFilterHome((prevFilterHome) => ({
            ...prevFilterHome,
            [name]: newValue,
        }))
    }
    const handleChangeInput = (e) => {
        const {name, value} = e.target ? e.target : e
        e.preventDefault()
        setTextValue({
            value: value,
        });
        setFilterHome((prevFilterHome) => ({
            ...prevFilterHome,
            [name]: value,
        }))
    }

    const resetFilter = useCallback(async () => {
        navigate("/");
        await dispatch(
            applicationsAll({
                paginate: currentPage,
            })
        );
        localStorage.removeItem("filter")
        searchRef.current.value = ""
        partnerRef.current.value = ""
        parkingRef.current.value = ""
        setPartnerValue(null)
        setParkingValue(null)
        setSortByValue(null)
        setFilterHome([])
    }, [])
    return (
        <form
            className="filter filter-mob-style d-flex gap-3 main-page-filter">
            <label className="field-style">
                <span> Поиск по тексту</span>
                <input
                    type="text"
                    defaultValue={textValue ? textValue : ''}
                    ref={searchRef}
                    name="search"
                    onChange={handleChangeInput}
                    className={'input-cst-height'}
                    onKeyPress={(e) => {
                        e.key === "Enter" && filterFunction(e)
                    }}
                />
            </label>
            {/*  */}

            {/* Фильтр заказчика */}
            <label className="field-style select-react__style ">
                <span>Заказчик</span>
                <Select
                    defaultValue={ partnerList && partnerList[0]}
                    value={ partnerValue }
                    name="partner"
                    options={partnerList}
                    ref={partnerRef}
                    styles={selectStyle}
                    onChange={(e) => {
                        filterFunction(e)
                    }}
                />
            </label>
            {/*  */}

            {/* фильтр по парковкам */}
            <label className="field-style mr-0 select-react__style ">
                <span>Стоянка</span>
                <Select
                    defaultValue={parkingList && parkingList[0]}
                    value={parkingValue}
                    name="parking"
                    options={parkingList}
                    ref={parkingRef}
                    styles={selectStyle}
                    onChange={(e) => { filterFunction(e)}}
                />
            </label>
            {/*  */}

            {/* Сортировка */}
            <label className="field-style sorted-by mr-0 select-react__style ">
                <span>Сортировка</span>
                <Select
                    defaultValue={sortByList && sortByList[0]}
                    value={sortByValue}
                    name="sort_by"
                    options={sortByList}
                    getOptionLabel={(e) => (
                        <div data-class={e.isDisabled ? "option-disabled" : "option-active"}>
                            {e.label}
                        </div>
                    )}
                    ref={parkingRef}
                    styles={selectStyle}
                    onChange={(e) => { filterFunction(e)}}
                />
            </label>
            {/*  */}

            {/* Фильтр по фаворитам */}
            <label className="chosen-post">
                <input
                    type="checkbox"
                    name="favorite"
                    className="checkbox-chosen"
                    value={1}
                    onChange={(e) => {
                        filterFunction(e)
                    }}
                />
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 2.5C12.3788 2.5 12.7251 2.714 12.8945 3.05279L15.4734 8.2106L21.144 9.03541C21.5206 9.0902 21.8335 9.35402 21.9511 9.71599C22.0687 10.078 21.9706 10.4753 21.6981 10.741L17.571 14.7649L18.4994 20.4385C18.5608 20.8135 18.4043 21.1908 18.0957 21.4124C17.787 21.6339 17.3794 21.6614 17.0438 21.4834L12 18.8071L6.95624 21.4834C6.62062 21.6614 6.21306 21.6339 5.9044 21.4124C5.59573 21.1908 5.4393 20.8135 5.50065 20.4385L6.42906 14.7649L2.30193 10.741C2.02942 10.4753 1.93136 10.078 2.04897 9.71599C2.16658 9.35402 2.47946 9.0902 2.85609 9.03541L8.5267 8.2106L11.1056 3.05279C11.275 2.714 11.6213 2.5 12 2.5ZM12 5.73607L10.082 9.57221C9.93561 9.86491 9.65531 10.0675 9.33147 10.1146L5.14842 10.723L8.19813 13.6965C8.43182 13.9243 8.53961 14.2519 8.4869 14.574L7.80004 18.7715L11.5313 16.7917C11.8244 16.6361 12.1756 16.6361 12.4687 16.7917L16.2 18.7715L15.5132 14.574C15.4604 14.2519 15.5682 13.9243 15.8019 13.6965L18.8516 10.723L14.6686 10.1146C14.3448 10.0675 14.0645 9.86491 13.9181 9.57221L12 5.73607Z"
                        fill="#536E9B"
                    ></path>
                    <path
                        d="M9 9L3.5 10L7.5 14.5L7 20.5L12 18L17.5 20.5L16.5 14L21 10L15 9L12 4L9 9Z"
                        fill="transparent"
                    ></path>
                </svg>
            </label>
            {/*  */}

            {/*reset*/}
            <label className="custom-input-wrap pl-2" onClick={resetFilter}>
                <input type="checkbox" name="uncheckFilters"/>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                        fill="#536E9B"
                    ></path>
                </svg>
            </label>
        </form>
    );
}

export default ApplicationSearch;
