import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {imageDelete} from "../../store/image/image-temperaty/imageTemperarySlice";
import { uploadImageEditSlice } from "../../store/image/image-upload-edit/uploadImageEdit";
import { applicationsEditGet } from "../../store/applications/applications-edit/applications-edit-get/applicationsEditSlice";
import { applicationsSingle } from "../../store/applications/applications-single/applicationsSingleSlice";
import { Gallery, Item } from "react-photoswipe-gallery";
import {getDataFromExcelFile} from "../../store/applications/application-import/applicationImportSlice";
import {useLocation} from "react-router-dom";

const ImageUploaderEdit = ({ id, oldimage }) => {
  const [imageState, setImagesState] = useState([]);
  const [allImages, setAllImages] = useState([])
  const dispatch = useDispatch();
  const location = useLocation();
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result;
        const newImage = {
          id: file.name,
          data: base64Data,
        };
        setImagesState((prevImages) => [...prevImages, newImage]);
        dispatch(
          uploadImageEditSlice({
            id: id,
            data: {
              file: base64Data,
              name: file.name,
            },
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(applicationsEditGet(id));
            if (location.pathname === '/applications/import'){
             dispatch(getDataFromExcelFile())
            }
          }
        });
      };
      reader.readAsDataURL(file);
    });
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(imageState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // setImagesState(items);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteImage = (id) => {
    const updatedImages = oldimage.filter((item) => item.id !== id);
    setImagesState(updatedImages);
    dispatch(imageDelete(id)).then((resp) =>{
      if (resp.meta.requestStatus === "fulfilled"){
        dispatch(applicationsSingle(id))
      }
    })
  };

  useEffect(() => {
    if (imageState.length !== 0) {
      dispatch(applicationsEditGet(id));
      dispatch(applicationsSingle(id));
    }
  }, [imageState]);

  useEffect(() => {
    dispatch(applicationsSingle(id)).then((resp) => {
      if (resp.payload?.application?.images) {
        setAllImages(resp.payload.application.images);
      } else {
        setAllImages([]); // Если данных нет, то просто пустой массив
      }
    });
  }, [oldimage, imageState, dispatch]);


  useEffect(() => {
    if (oldimage && Array.isArray(oldimage)) {
      setAllImages(oldimage.length > 0 ? oldimage : []);
    }
  }, [oldimage]);


  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {/*<div className="inner-item-title">Фотографии</div>*/}
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="d-flex flex-wrap flex-row"
            >
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <div>
                    <div className="page-file-list" id="images">
                      <div className="page-add-file no-ajax upload-file">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M20.0013 6.6665C20.9218 6.6665 21.668 7.4127 21.668 8.33317V18.3332H31.668C32.5884 18.3332 33.3346 19.0794 33.3346 19.9998C33.3346 20.9203 32.5884 21.6665 31.668 21.6665H21.668V31.6665C21.668 32.587 20.9218 33.3332 20.0013 33.3332C19.0808 33.3332 18.3346 32.587 18.3346 31.6665V21.6665H8.33464C7.41416 21.6665 6.66797 20.9203 6.66797 19.9998C6.66797 19.0794 7.41416 18.3332 8.33464 18.3332H18.3346V8.33317C18.3346 7.4127 19.0808 6.6665 20.0013 6.6665Z"
                            fill="#536E9B"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Gallery>
                {allImages &&
                allImages.length !== 0 &&
                allImages.map((image, index) => {
                    return (
                      <Item
                        original={image.url}
                        thumbnail={image.url}
                        width="1024"
                        height="768"
                        key={index}
                      >
                        {({ ref, open }) => (
                          <Draggable
                            key={image.id}
                            draggableId={image.url}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="page-file-item"
                              >
                                <img
                                  src={image.url}
                                  alt={`Image ${index + 1}`}
                                  className="image-preview"
                                  ref={ref}
                                />
                                <div className="page-file__option">
                                  <button
                                    type="button"
                                    className="page-file__zoom"
                                    onClick={open}
                                  ></button>
                                  <button
                                    type="button"
                                    className="page-file__delete"
                                    onClick={(e) => handleDeleteImage(image.id)}
                                  ></button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )}
                      </Item>
                    );
                  })}
              </Gallery>


              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ImageUploaderEdit;
