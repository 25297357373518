import axios from "axios";

const API_URL = "/v1/applications/";

// Application All LiST
const applicationEditGet = async (id) => {
  const response = await axios.get(API_URL + id + "/edit");
  return response.data;
};

const applicationEditPut = async (id, data, role) => {
  const {
    arriving_at,
    arriving_interval,
    courier_fullname,
    courier_phone,
    owner_fullname,
    owner_phone,
    external_id,
    parking_id,
    partner_id,
    status_id,
  } = data;

  let app_data = {
    arriving_at,
    arriving_interval,
    courier_fullname,
    courier_phone,
    owner_fullname,
    owner_phone,
    external_id,
    parking_id,
    partner_id,
    status_id,
  };

  // Собираем элементы appData
  // let app_data;
  // if (role === "Operator") {
  //   const {
  //     arriving_at,
  //     arriving_interval,
  //     courier_fullname,
  //     courier_phone,
  //     external_id,
  //     parking_id,
  //     partner_id,
  //     status_id,
  //   } = data;

  //    app_data = {
  //     arriving_at,
  //     arriving_interval,
  //     courier_fullname,
  //     courier_phone,
  //     external_id,
  //     parking_id,
  //     partner_id,
  //     status_id,
  //   };
  // } else {
  //   const {
  //     arriving_at,
  //     arriving_interval,
  //     courier_fullname,
  //     courier_phone,
  //     external_id,
  //     parking_id,
  //     partner_id,
  //     status_id,
  //   } = data;

  //    app_data = {
  //     arriving_at,
  //     arriving_interval,
  //     courier_fullname,
  //     courier_phone,
  //     external_id,
  //     parking_id,
  //     partner_id,
  //     status_id,
  //   };
  // }

  //

  // Собираем элементы CarData
  const {
    vin_array,
    license_plate,
    license_plate_status,
    vin_status,
    owner_number,
    color,
    sts,
    pts_provided,
    sts_provided,
    pts,
    pts_type,
    car_title,
    car_key_quantity,
    milage,
    car_additional,
    car_type_id,
    car_subtype_id,
    car_mark_id,
    car_model_id,
    year,
    custom_year,
    car_generation_id,
    car_series_id,
    car_modification_id,
    car_engine_id,
    car_transmission_id,
    car_gear_id,
    condition_engine,
    condition_transmission,
    condition_electric,
    condition_gear,
    car_mark,
    car_model,
    car_generation,
    car_series,
    car_modification,
    car_engine,
    car_transmission,
    car_gear,
  } = data;

  let car_data = {
    vin_array,
    license_plate,
    license_plate_status,
    owner_number,
    color,
    sts,
    pts_provided,
    sts_provided,
    pts,
    pts_type,
    car_title,
    car_key_quantity,
    milage,
    vin_status,
    car_additional,
    car_type_id,
    car_subtype_id,
    car_mark_id,
    car_model_id,
    year,
    custom_year,
    car_generation_id,
    car_series_id,
    car_modification_id,
    car_engine_id,
    car_transmission_id,
    car_gear_id,
    condition_engine,
    condition_transmission,
    condition_electric,
    condition_gear,
    car_mark,
    car_model,
    car_generation,
    car_series,
    car_modification,
    car_engine,
    car_transmission,
    car_gear,
  };
  //
  //

  // Собираем изображение
  const _method = "PUT";
  const docs = data.docs;
  //

  let q = {
    app_data,
    car_data,
    docs,
  };

  console.log(q)
  // const response = await axios.post(API_URL + id, q, {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // });


  const response = await axios.put(API_URL + id, q);
  return { data: response.data, status: response.status };
};

const applicationEditService = {
  applicationEditGet,
  applicationEditPut,
};

export default applicationEditService;
