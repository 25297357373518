// Собираем массив
export const selectArray = (array, name) => {
    const options = [];
    array &&
    array.map((item) => {
        return options.push({
            value: item.id,
            label: item.name,
            name: name,
        });
    });
    return options;
};
//

// Собираем массив для парковки
export const selectArrayParking = (array, name) => {
    const options = [];
    array &&
    array.map((item) => {
        return options.push({
            value: item.id,
            label: item.title,
            name: name,
        });
    });
    return options;
};
//

// Собираем массив для сортировки
export const selectArraySorting = (array, name) => {
    const options = [];
    if (!array || array.length === 0) return options;
    options.push({label: "сортировка по сообщениям", value: "messages", isDisabled: true});
    array.forEach((item, index) => {
      options.push({
        value: item.code,
        label: item.title,
        name: name,
      })
      if (index === 0) {
        options.push({ label: "сортировка по статусу услуг", value: "status", isDisabled: true });
      }
    })
    return options;
};
//

//building array for mass upload car types
export const selectArrayCarTypes = (array, name) => {
    const options = [];
    array &&
    array.map((item) => {
        return options.push({
            value: item.id,
            label: item.name,
            name: name,
        });
    });
    return options;
};
//

// Собираем массив для роли
export const selectArrayRoles = (array) => {
    const options = [];
    array &&
    array.map((item, index) => {
        return options.push({
            value: item,
            label: item,
            name: "role",
        });
    });
    return options;
};
//

// Собираем массив для цвета
export const selectColor = (array) => {
    const options = [];
    array &&
    array.map((item, index) => {
        return options.push({
            value: item.value,
            label: item.label,
            name: "color",
        });
    });
    return options;
};
//

// Собираем массив для интервала времени
export const selectArrayInterval = [
    {
        value: "10:00 - 14:00",
        label: "10:00 - 14:00",
        name: "arriving_interval",
    },
    {
        value: "14:00 - 18:00",
        label: "14:00 - 18:00",
        name: "arriving_interval",
    },
];
//

// Собираем массив для ПТС
export const pts = [
    {value: "Электронный", label: "Электронный", name: "pts_type"},
    {value: "Оригинал", label: "Оригинал", name: "pts_type"},
    {value: "Дубликат", label: "Дубликат", name: "pts_type"},
];
//

// Собираем массив для отчета
export const statusForReports = [
    {value: "instorage", label: "Хранение", name: "status_id"},
    {value: "arrived", label: "Были на Хранение", name: "status_id"},
    {value: "issued", label: "Выданные", name: "status_id"},
];
//

// Собираем массив
export const selectArrayPartner = (array, name) => {
    const options = [];
    array &&
    array.map((item) => {
        return options.push({
            value: item.id,
            label: item.shortname,
            name: name,
        });
    });
    return options;
};
//

export const basePartner = [
    {value: "public", label: "Общая", name: "base"},
    {value: "user", label: "Пользовательская", name: "base"},
];

export const partnerUserSelect = [
    {value: "1", label: "Активен", name: "status"},
    {value: "0", label: "Не активен", name: "status"},
];
