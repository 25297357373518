import React, {useCallback, useEffect, useState} from "react";
import HeaderNotificationMessages from "./header-notification/header-notification-messages";
import HeaderProfile from "./header-profile/header-profile";
import ApplicationItemModal
    from "../../applications/applications-index/applications-list/applications-item-modal/applications-item-modal";
import {useDispatch, useSelector} from "react-redux";
import {notificationData} from "../../../store/notofocations/headerNotificationSlice";
import {db} from "../../../firebase";
import HeaderNotification from "./header-notification/header-notification";
import {limitToLast, onValue, ref} from "firebase/database";
import {applicationsSingle} from "../../../store/applications/applications-single/applicationsSingleSlice";
import {currentApplicationServiceById} from "../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {useLocation, useNavigate} from "react-router-dom";

const RightMenu = () => {
    const dispatch = useDispatch()
    const [notifications, setNotifications] = useState([]);
    const [notificationFromFireBase, setNotificationFromFireBase] = useState({});
    const {user} = useSelector((state) => state.auth);
    //modal
    const {single} = useSelector((state) => state.applicationSingle);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [favorite, setFavorite] = useState(0);
    const [error, setError] = useState(null);
    const [currentModalInfo, setCurrentModalInfo] = useState(null);
    const navigate = useNavigate();
    const location = useLocation()
    //set active tab
    const [activeTabKey, setActiveTabKey] = useState({
        tabName: 'tab1',
        serviceId: 0,
        applicationId: 0
    })

    const openServicesModal = () => {
        setIsServiceModalOpen(true)
    }

    const isModalOpen = location.pathname.startsWith("/modal/");
    const openModalFromNotification = useCallback(
        (id, type, orderID) => {
            const applicationId = id
            dispatch(applicationsSingle(id)).then((resp) => {
                setCurrentModalInfo(resp.payload)
            });

            if (type === 'chat') {
                navigate(`/modal/${id}/chat`)
            }
            else if (type === 'order') {
                dispatch(currentApplicationServiceById({applicationId, orderID}))
                navigate(`/modal/${id}/service/${orderID}`)
                setActiveTabKey({
                    tabName: 'tabCurrentServices',
                    serviceId: orderID,
                    applicationId: id
                })

            } else {
                navigate(`/modal/${id}/service`)
                setActiveTabKey({
                    tabName: 'service',
                    serviceId: 0,
                    applicationId: id
                })
            }

        }, [])
    const closeModal = () => {
        navigate(`/`)
        setCurrentModalInfo(null)
    };
    const closeServicesModal = () => {
        setIsServiceModalOpen(false)
    }
    //myparkingdev  (dev)
    //myparkingprod  (prod)
    useEffect(() => {
        const notificationsRef = ref(db, `myparkingprod/notifications/users/${user.id}`, limitToLast(1))
        const unsubscribe = onValue(notificationsRef, (snapshot) => {
            setNotificationFromFireBase(snapshot.val())
            setError(null)
        }, (error) => {
            setError(error.message)
        });
        return () => {
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        dispatch(notificationData()).then((resp) => {
            setNotifications(resp.payload?.notifications)
        })
    }, [dispatch])

    useEffect(() => {
        if (notificationFromFireBase) {
            setNotifications((prevNotifications) => {
                const exists = prevNotifications.some(
                    (notification) => notification.notification_id === notificationFromFireBase.notification_id
                );
                if (!exists) {
                    return [notificationFromFireBase, ...prevNotifications];
                }
                return prevNotifications;
            });
        }
    }, [notificationFromFireBase])

    return (
        <>
            <div className="header__user ms-auto d-flex align-items-center">
                <HeaderNotificationMessages
                    notifications={notifications}
                    openModal={openModalFromNotification}
                    setActiveTabKey={setActiveTabKey}
                    setNotifications={setNotifications}
                />
                <HeaderNotification
                    notifications={notifications}
                    openModal={openModalFromNotification}
                    setActiveTabKey={setActiveTabKey}
                    setNotifications={setNotifications}
                />
                <HeaderProfile/>
            </div>
            {isModalOpen && currentModalInfo ?
                <ApplicationItemModal
                    single={currentModalInfo}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    setFavorite={setFavorite}
                    openServicesModal={openServicesModal}
                    isServiceModalOpen={isServiceModalOpen}
                    closeServicesModal={closeServicesModal}
                    tab={activeTabKey}
                /> : ''
            }
        </>
    )
}

export default RightMenu;
