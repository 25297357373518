import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationTypeService from "../applicationSelectService";

const initialState = {
  generations: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Type cars
export const applicationsGenerations = createAsyncThunk(
  "applications/applicationsGenerations",
  async ({ modelId, year, seriesId }, thunkAPI) => {
    try {
      return await applicationTypeService.appGenerations(modelId, year, seriesId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationGenerationsSlice = createSlice({
  name: "applicationsGenerations",
  initialState,
  reducers: {
    generationReset: (state) => {
      state.generations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsGenerations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsGenerations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.generations = action.payload;
      })
      .addCase(applicationsGenerations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.generations = null;
      });
  },
});
export const { generationReset } = applicationGenerationsSlice.actions;

export default applicationGenerationsSlice.reducer;
