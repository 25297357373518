import React, {useCallback, useEffect, useState} from "react";
import Preloader from "../../../preloader/preloader";
import ApplicationItem from "./applications-item/applications-item";
import {ToastContainer} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {applicationsSingle} from "../../../../store/applications/applications-single/applicationsSingleSlice";
import {applicationServicesGetById} from "../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {useDispatch} from "react-redux";
import ApplicationItemModal from "./applications-item-modal/applications-item-modal";
import {isNaN} from "formik";

function ApplicationsList({applications, setFavorite}) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentModalInfo, setCurrentModalInfo] = useState(null);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

    const isModalOpen = location.pathname.startsWith("/modal/");
    const [activeTabKey, setActiveTabKey] = useState({
        tabName: 'serviceList',
        serviceId: 0,
        applicationId: 0
    })

    useEffect(() => {
        if (location.pathname.startsWith("/modal/") ) {
            const match = location.pathname.match(/^\/modal\/(\d+)(?:\/service(?:\/(\d+))?)?(?:\/chat)?\/?$/);
            if (match) {
                const id = match[1];
                const serviceId = match[2] || null; // ID после /current-service/ (если есть)
                openModal(id, serviceId)
            }
            else {
               const appId = match[1]
                if (appId && !isNaN(appId)){
                    openModal(appId)
                }
            }
        }
    }, [])

    const openModal = (id, serviceId = null) => {
        dispatch(applicationsSingle(id)).then((resp) => {
            setCurrentModalInfo(resp.payload)
        })
        if (location.pathname.includes('/chat')){
            setActiveTabKey({
                tabName: 'chat',
                serviceId: 0,
                applicationId: id
            })
        }
        else if (location.pathname.includes('service') && (serviceId && !isNaN(serviceId))){
            setActiveTabKey({
                tabName: 'tabCurrentServices',
                serviceId: parseInt(serviceId),
                applicationId: parseInt(id)
            })
        }
         else if (location.pathname.includes('/service')){
            setActiveTabKey({
                tabName: 'service',
                serviceId: 0,
                applicationId: 0
            })
        }
        else{
            setActiveTabKey({
                tabName: 'service',
                serviceId: 0,
                applicationId: 0
            })
            navigate(`/modal/${id}/service`)
        }
        let currentApplicationId = id
        dispatch(applicationServicesGetById({currentApplicationId}))
    }

    const closeModal = () => {
        navigate("/")
        setCurrentModalInfo(null)
    }

    const openServicesModal = () => {
        setIsServiceModalOpen(true)
    }

    const closeServicesModal = () => {
        setIsServiceModalOpen(false)
    }

    return (
        <>
            <section className="car-col">
                <div className="container">
                    <div className="row">
                        {applications && applications.length !== 0 ? (
                            Object.keys(applications).length !== 0 &&
                            applications?.applications.map((item) => {
                                return (
                                    <ApplicationItem
                                        key={item.id}
                                        item={item}
                                        openModal={openModal}
                                        closeModal={closeModal}
                                        isModalOpen={isModalOpen}
                                        currentModalInfo={currentModalInfo}
                                        setFavorite={setFavorite}
                                    />
                                );
                            })
                        ) : (
                            <Preloader/>
                        )}
                    </div>
                </div>
            </section>
            {isModalOpen && currentModalInfo ?
                <ApplicationItemModal
                    single={currentModalInfo}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    setFavorite={setFavorite}
                    openServicesModal={openServicesModal}
                    isServiceModalOpen={isServiceModalOpen}
                    closeServicesModal={closeServicesModal}
                    tab={activeTabKey}
                /> : ''}
        </>
    );
}

export default ApplicationsList;
