import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {uploadImageSlice} from "../../store/image/image-upload/uploadImageSlice";
import {
    imageDelete,
    imageTempararyData,
} from "../../store/image/image-temperaty/imageTemperarySlice";
import {Gallery, Item} from "react-photoswipe-gallery";

const ImageUploader = () => {
    const imageData = useSelector((state) => state.imageTemparary);
    const dispatch = useDispatch();

    const [imageState, setImagesState] = useState([])
    const tokenAttachment = localStorage.getItem("attachment_token");
    //clear temporary images by loading component
    useEffect(() => {
        if (tokenAttachment) {
            dispatch(imageTempararyData(tokenAttachment))
                .then((resp) => {
                    if (resp.payload && resp.payload.length > 0) {
                        const tempImages = resp.payload || []
                        tempImages.forEach((img) => {
                            dispatch(imageDelete(img.id))
                        })
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
        }
    }, [])
    //save temporary images to localState to add loading animation
    useEffect(() => {
        setImagesState(imageData.image)
    }, [imageData])

    const onDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                const data = {
                    file: base64Data,
                    _token: tokenAttachment,
                    name: file.name,
                };
                dispatch(uploadImageSlice({data}))
                    .then((res) => {
                        if (res.meta.requestStatus === "fulfilled") {
                            dispatch(imageTempararyData(tokenAttachment))
                                .then((res) => {
                                    let progress = 0
                                    const interval = setInterval(() => {
                                        progress += 10
                                        setImagesState((prevImages) =>
                                            prevImages.map((img, index) =>
                                                index === prevImages.length - 1 ? {
                                                    ...img,
                                                    uploadProgress: progress
                                                } : {...img, uploadProgress: 100}
                                            )
                                        );
                                        if (progress >= 100) {
                                            clearInterval(interval);
                                        }
                                    }, 200);

                                })
                        }
                    })
                    .catch((error) =>
                        console.error("Failed to upload image:", error)
                    );
            }
            reader.readAsDataURL(file);
        });
    };
    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) return

            const items = Array.from(imageState)
            const [reorderedItem] = items.splice(result.source.index, 1)
            items.splice(result.destination.index, 0, reorderedItem)
            setImagesState(items)
        }, [imageState])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const handleDeleteImage = (id) => {
        dispatch(imageDelete(id)).then((resp) => {
            if (resp.meta.requestStatus === "fulfilled") {
                setImagesState((prevImages) =>
                    prevImages.map((img, index) =>
                        index === prevImages.length - 1 ? {...img, uploadProgress: 100} : {...img, uploadProgress: 100}
                    )
                );
            }
        })
    };
    // Подгружаем временные изображения
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="inner-item-title">Фотографии</div>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="d-flex flex-wrap flex-row"
                        >
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <div>
                                        <div className="page-file-list" id="images">
                                            <div className="page-add-file no-ajax upload-file">
                                                <svg
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        opacity="0.6"
                                                        d="M20.0013 6.6665C20.9218 6.6665 21.668 7.4127 21.668 8.33317V18.3332H31.668C32.5884 18.3332 33.3346 19.0794 33.3346 19.9998C33.3346 20.9203 32.5884 21.6665 31.668 21.6665H21.668V31.6665C21.668 32.587 20.9218 33.3332 20.0013 33.3332C19.0808 33.3332 18.3346 32.587 18.3346 31.6665V21.6665H8.33464C7.41416 21.6665 6.66797 20.9203 6.66797 19.9998C6.66797 19.0794 7.41416 18.3332 8.33464 18.3332H18.3346V8.33317C18.3346 7.4127 19.0808 6.6665 20.0013 6.6665Z"
                                                        fill="#536E9B"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Gallery>
                                {imageState &&
                                imageState?.length !== 0 &&
                                imageState?.map((imageSingle, index) => (
                                    <Item
                                        original={imageSingle.url}
                                        thumbnail={imageSingle.thumbnail_url}
                                        width="1024"
                                        height="768"
                                        key={index}
                                    >
                                        {({ref, open}) => (
                                            <Draggable
                                                key={imageSingle.id}
                                                draggableId={imageSingle.url}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="page-file-item"
                                                    >
                                                        {imageSingle.uploadProgress < 100 && (
                                                            <div className="container-img-upload">
                                                                <div className="spinner"
                                                                     style={{'--perc': imageSingle.uploadProgress}}></div>
                                                            </div>
                                                        )}
                                                        {(imageData?.isLoading) && (

                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>)
                                                        }
                                                        {(imageSingle.uploadProgress === 100 && imageData?.isSuccess) &&
                                                        <img
                                                            src={imageSingle.url}
                                                            alt={`Image ${index + 1}`}
                                                            className="image-preview"
                                                            ref={ref}
                                                        />
                                                        }
                                                        {imageSingle.uploadProgress === 100 && (
                                                            <div className="page-file__option">
                                                                <button
                                                                    type="button"
                                                                    className="page-file__zoom"
                                                                    onClick={open}
                                                                ></button>
                                                                <button
                                                                    type="button"
                                                                    className="page-file__delete"
                                                                    onClick={(e) => handleDeleteImage(imageSingle.id)}
                                                                ></button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        )}
                                    </Item>
                                ))}
                            </Gallery>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default ImageUploader;