import React, {useState} from "react";
import Select from "react-select";
import {useDispatch} from "react-redux";
import InputMask from "react-input-mask";
import moment from "moment";

import {
    selectArrayParking,
    selectArrayInterval,
    selectArray,
} from "../../../../utils/select-array";

import selectStyle from "../../../../utils/select-styles";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {colorConfomity} from "../../../../utils/status-conformity";
import {
    serialNumberReset,
    vinDublicateData,
} from "../../../../store/applications/application-vin-dublicate/applicationVinDublicateSlice";
import {useEffect} from "react";
import {parkingsData} from "../../../../store/parkings/parkingsSlice";
import ModalParkingMap from "../../../modal-parking-map/modal-parking-map";
import {editParkingService} from "../../../../store/parkings/parking-edit-services-slice/parkingEditServicesSlice";
import ModalParkingServices from "../../../modal-parking-services/modal-parking-services";

function ApplicationEditStep1(
    {
        applications,
        partners,
        parkings,
        dateTime,
        setUpdateAppData,
        updateAppData,
    }) {
    const dispatch = useDispatch();
    const partnerSelect = selectArray(partners, "partner_id");
    const parkingsSelect = selectArrayParking(parkings, "parking_id");
    const intervalSelect = selectArrayInterval;
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const {single} = useSelector((state) => state.applicationSingle);
    const allParkingsList = useSelector((state) => state.parkings);
    const [selectedOption, setSelectedOption] = useState({
        value: 0,
        label: "Выберите стоянку",
        name: "parking_id",
    });

    const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
    const [currentServicesName, setCurrentServicesName] = useState('');
    const [externalInput, setExternalInput] = useState({
        externalClass: "",
        externalDisable: false,
    });
    const parkingServices = useSelector((state) => state.parkingServices.parkingServices?.parking_services)
    const {user} = useSelector((state) => state.auth)

    const openMapModal = () => {
        setIsMapModalOpen(true);
        dispatch(parkingsData());
    }
    const closeMapModal = () => {
        setIsMapModalOpen(false);
    }
    //выбор стоянки из списка , если выбрана стоянка на крте
    const selectOptionById = (id) => {
        const selected = parkingsSelect.find(option => option.value === id);

        if (selected) {
            setSelectedOption(selected);
        }
    }
    const openServicesModal = (id, name) => {
        setIsServicesModalOpen(true)

        let partner_user_id = null
        if (user.role === 'Partner'){
            partner_user_id = user.id
        }
        const partner_id = updateAppData.partner_id
        if (id === 0) {
            let id = applications.parking?.id
            setCurrentServicesName(applications.parking?.title)
            dispatch(editParkingService({id, partner_id, partner_user_id}))
        } else {
            setCurrentServicesName(name)
            dispatch(editParkingService({id, partner_id, partner_user_id}))
        }
    }
    const closeServicesModal = () => {
        setIsServicesModalOpen(false)
    }
    const externalCheckbox = (e) => {
        if (e.target.checked) {
            setExternalInput({
                externalClass: "disabled",
                externalDisable: true,
            });
        } else {
            setExternalInput({
                externalClass: "",
                externalDisable: false,
            });
        }
    }

    const dateDelivery = moment(
        applications?.formated_arrived_at,
        "DD.MM.YYYY"
    ).format("YYYY-MM-DD")

    const {serial_number} = useSelector(
        (state) => state.applicationVinDublicate
    )

    const handleInputChange = (event) => {
        const {name, value, type, checked, label} = event.target ? event.target : event;
        let newValue = value;
        if (type === "checkbox") {
            newValue = checked ? 1 : 0;
        }
        setUpdateAppData({
            ...updateAppData,
            [name]: isNaN(newValue) ? newValue : newValue,
        });
        if (name === "parking_id") {
            setSelectedOption({
                value: value,
                label: label,
                name: "parking_id",
            })
        }
    }


    const handleVinDublicate = (vin) => {
        dispatch(vinDublicateData(vin));
    }

    useEffect(() => {
        if (applications.vin_status === 0) {
            dispatch(
                vinDublicateData(
                    updateAppData.vin_array === "не указан" ? "" : updateAppData.vin_array
                )
            );
        }
    }, [])

    // Чекбокс проверка vin
    const [vinInput, setVinInput] = useState({
        vin: applications.vin,
        vinClass: "",
        vinDisable: applications.vin === "не указан" ? true : false,
    })
    const vinCheckbox = (e) => {
        const {name, value, type, checked} = e.target ? e.target : e
        dispatch(serialNumberReset())
        if (e.target.checked) {
            setVinInput({
                vinClass: "disabled",
                vinDisable: true,
            });
            setUpdateAppData({...updateAppData, vin_array: "", vin_status: 1})
        } else {
            setVinInput({
                vinClass: "",
                vinDisable: false,
            });

            let vinStatusInput = value
            if (type === "checkbox") {
                vinStatusInput = checked ? 1 : 0
            }
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(vinStatusInput)
                    ? vinStatusInput
                    : parseInt(vinStatusInput),
            });
        }
    }
    //

    // Чекбокс гос.номер
    const [licenseInput, setLicenseInput] = useState({
        licenseClass: "",
        licenseDisable: applications.license_plate === "не указан" ? true : false,
    })
    const licenseCheckbox = (e) => {
        const {name, value, type, checked} = e.target ? e.target : e

        if (e.target.checked) {
            setLicenseInput({
                licenseClass: "disabled",
                licenseDisable: true,
            });

            setUpdateAppData({
                ...updateAppData,
                license_plate: "",
                license_plate_status: 1,
            });
        } else {
            setLicenseInput({
                licenseClass: "",
                licenseDisable: false,
            });

            let licencePlateStatusInput = value
            if (type === "checkbox") {
                licencePlateStatusInput = checked ? 1 : 0
            }
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(licencePlateStatusInput)
                    ? licencePlateStatusInput
                    : parseInt(licencePlateStatusInput),
            });
        }
    }
    //
    let parts
    if (user.role === "Operator") {
        parts = applications.arriving_date.split(" ")
    }

    return (
        <>
            {applications && (
                <div
                    className="row no-gutters tab-pane fade show active"
                    id="v-pills-1"
                >
                    <div className="col-md-12 main-col">
                        {/* Административная информация */}
                        <div className="inner-page__item">
                            <div className="inner-item-title">
                                Административная информация
                            </div>
                            <div className="row">
                                {/* VIN */}
                                <div className="col-6">
                                    <label
                                        className={`field-style ${vinInput.vinClass} ${
                                            updateAppData.vin_array && updateAppData.vin_array !== ""
                                                ? ""
                                                : "invalid"
                                        } `}
                                    >
                                        <span>VIN</span>
                                        <input
                                            type="text"
                                            className="vin"
                                            name="vin_array"
                                            placeholder="Не указан"
                                            value={updateAppData.vin_array}
                                            onChange={handleInputChange}
                                            onBlur={(e) =>
                                                handleVinDublicate(updateAppData.vin_array)
                                            }
                                            disabled={vinInput.vinDisable}
                                        />
                                    </label>
                                    {/* Неправильный vin номер */}
                                    {serial_number.error && (
                                        <span className="vin__error">{serial_number.error}</span>
                                    )}
                                    {/*  */}
                                    <label className="switch-radio-wrap mt-2">
                                        <input
                                            className="checkbox-unknown cvin"
                                            type="checkbox"
                                            name="vin_status"
                                            value={applications.vin_status}
                                            defaultChecked={vinInput.vin === "не указан"}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                vinCheckbox(e);
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>неизвестно</span>
                                    </label>
                                </div>
                                {/*  */}

                                {/* Гос.номер */}
                                <div className="col-6">
                                    <label className="field-style">
                                        <span>Гос. номер</span>
                                        <input
                                            type="text"
                                            className="license_plate"
                                            name="license_plate"
                                            placeholder="Не указан"
                                            defaultValue={updateAppData.license_plate}
                                            onChange={handleInputChange}
                                            disabled={licenseInput.licenseDisable}
                                        />
                                    </label>
                                    <label className="switch-radio-wrap mt-2">
                                        <input
                                            className="checkbox-unknown clicense"
                                            type="checkbox"
                                            data-for="license_plate"
                                            name="license_plate_status"
                                            value={applications.license_plate_status}
                                            onChange={(e) => {
                                                handleInputChange(e)
                                                licenseCheckbox(e)
                                            }}
                                            defaultChecked={
                                                applications.license_plate === "не указан"
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>неизвестно</span>
                                    </label>
                                </div>
                                {/*  */}

                                {/* DUBLICATE */}
                                {serial_number.length !== 0 ? (
                                    <div className="col-12 mt-3">
                                        <div id="allDuplicates" className="conformity">
                                            {serial_number.vins.map((item, index) => {
                                                return (
                                                    <Link
                                                        key={index}
                                                        to={
                                                            item.status.id === 2 || item.status.id === 7
                                                                ? `/applications/${item.id}/edit`
                                                                : ``
                                                        }
                                                        className="conformity-link"
                                                    >
                                                        <span className="conformity__info">{item.vin}</span>
                                                        <span
                                                            className={`${colorConfomity(
                                                                item.status.id
                                                            )} conformity__icon`}
                                                        >
                              {item.status.code}
                            </span>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {/*  */}
                            </div>
                        </div>
                        {/*  */}

                        {/* О собственнике */}
                        <div className="inner-page__item">
                            <div className="inner-item-title">О собственнике</div>
                            <div className="row mb-5">
                                <div className="col-6">
                                    <label className="field-style">
                                        <span>ФИО собственника</span>
                                        <input
                                            type="text"
                                            name="owner_fullname"
                                            placeholder="Не указан"
                                            defaultValue={applications.owner_fullname}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="col-6">
                                    <label className="field-style">
                                        <span>Телефон собствениика</span>
                                        <InputMask
                                            mask="+7 (999) 999-99-99"
                                            maskChar="_"
                                            name="owner_phone"
                                            defaultValue={applications.owner_phone}
                                            onChange={handleInputChange}
                                            placeholder="+7 (___) ___-__-__"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="field-style">
                                        <span>ФИО доставщика</span>
                                        <input
                                            type="text"
                                            name="courier_fullname"
                                            placeholder="Не указан"
                                            defaultValue={applications.courier_fullname}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="col-6">
                                    <label className="field-style">
                                        <span>Телефон доставщика</span>
                                        <InputMask
                                            mask="+7 (999) 999-99-99"
                                            maskChar="_"
                                            name="courier_phone"
                                            defaultValue={applications.courier_phone}
                                            onChange={handleInputChange}
                                            placeholder="+7 (___) ___-__-__"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/*  */}

                        <div className="inner-page__item">
                            <div className="inner-item-title">Системная информация</div>
                            <div className="row">
                                <input type="hidden" name="app_data[partner_id]"/>
                                {/* заказчик */}
                                {user.role !== 'Partner' && (
                                    <div className="col-3">
                                        <label className="field-style select-react__style">
                                            <span className="field-style-title">Заказчик*</span>
                                            <Select
                                                defaultValue={{
                                                    value: applications.partner?.id,
                                                    label: applications.partner?.name,
                                                    name: "partner_id",
                                                }}
                                                name="partner_id"
                                                options={partnerSelect}
                                                styles={selectStyle}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                    </div>
                                )}
                                {/*  */}


                                {/* Стоянка */}
                                <div className="col-3">
                                    <label className="field-style select-react__style">
                                        <span className="field-style-title">Стоянка*</span>
                                        <Select
                                            defaultValue={{
                                                value: applications.parking?.id,
                                                label: applications.parking?.title,
                                                name: "parking_id",
                                            }}
                                            options={parkingsSelect}
                                            styles={selectStyle}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>
                                {/*  */}

                                <div className="col-3 d-flex justify-content-start align-items-center pb-3">
                  <span
                      style={{cursor: 'pointer', marginRight: '40px'}}
                      onClick={() => openMapModal()}
                  >
                    выбрать на карте
                  </span>
                                    {selectedOption &&
                                    <span
                                        style={{cursor: 'pointer'}}
                                        onClick={() => openServicesModal(selectedOption.value, selectedOption.label)}
                                    > <span> - </span>
                      Прайс
                    </span>
                                    }
                                </div>

                                {/* Номер убытка или лизингового договора* */}
                                <div className="col-12 mt-3">
                                    <label className={`field-style w-100  ${externalInput.externalClass}`}>
                                        <span>Номер убытка или лизингового договора*</span>
                                        <input
                                            type="text"
                                            id="external_id"
                                            name="external_id"
                                            placeholder="Не указан"
                                            disabled={externalInput.externalDisable}
                                            defaultValue={applications.external_id}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                    <label className="switch-radio-wrap mt-2">
                                        <input
                                            className="checkbox-unknown cvin"
                                            type="checkbox"
                                            name="external_id"
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                externalCheckbox(e);
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>неизвестно</span>
                                    </label>
                                </div>
                                {/*  */}
                            </div>
                        </div>

                        <div className="inner-page__item">
                            <div className="inner-item-title">Дата</div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="field-style">
                                        <span>Дата поставки</span>
                                        <input
                                            type="date"
                                            className="date-manager"
                                            name="arriving_at"
                                            placeholder="Не указан"
                                            defaultValue={dateDelivery}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>

                                {user.role === "Operator" && dateTime && (
                                    <div className="col-4">
                                        <label className="field-style select-react__style">
                      <span className="field-style-title">
                        Промежуток времени
                      </span>
                                            <Select
                                                defaultValue={{
                                                    value: parts[1],
                                                    label: parts[1],
                                                    name: "arriving_interval",
                                                }}
                                                options={intervalSelect}
                                                styles={selectStyle}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ModalParkingMap
                single={single}
                isModalOpen={isMapModalOpen}
                closeModal={closeMapModal}
                allParkingsList={allParkingsList}
                handleInputChange={handleInputChange}
                selectOptionById={selectOptionById}
                openServicesModal={openServicesModal}
                closeServicesModal={closeServicesModal}
            />
            <ModalParkingServices
                isModalOpen={isServicesModalOpen}
                closeModal={closeServicesModal}
                parkingServices={parkingServices}
                currentServicesName={currentServicesName}
            />
        </>
    )
}

export default ApplicationEditStep1;
