import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageSlice } from "../../store/image/image-upload/uploadImageSlice";
import { imageDelete, imageTempararyData } from "../../store/image/image-temperaty/imageTemperarySlice";
import { Gallery, Item } from "react-photoswipe-gallery";

const CarImageUploader = ({ duplicateImages = [], setDuplicateApplicationData }) => {
    const imageData = useSelector((state) => state.imageTemparary);
    const dispatch = useDispatch();
    const tokenAttachment = localStorage.getItem("attachment_token");
    const [imageState, setImagesState] = useState([]);
    const [localDuplicateImages, setLocalDuplicateImages] = useState([]);

    // Загружаем временные данные (основные изображения)
    useEffect(() => {
        if (tokenAttachment) {
            try {
                dispatch(imageTempararyData(tokenAttachment))
            }catch (e){
                console.error('No images');
            }
        }
    }, [tokenAttachment, dispatch]);

    // Сохраняем дубликаты в локальный стейт и добавляем их ID в old_image_ids
    useEffect(() => {
        const markedDuplicates = duplicateImages.map((img) => ({
            ...img,
            isDuplicate: true, // Помечаем как дубликат
        }));

        setLocalDuplicateImages(markedDuplicates);

        // Добавляем все ID дубликатов в old_image_ids
        setDuplicateApplicationData((prevData) => ({
            ...prevData,
            old_image_ids: markedDuplicates.map((img) => img.id)
        }));
    }, [duplicateImages, setDuplicateApplicationData]);

    // Сохраняем реальные данные в imageState
    useEffect(() => {
        if (Array.isArray(imageData.image)) {
            setImagesState(imageData.image);
        }
    }, [imageData]);

    // Загрузка новых изображений на сервер
    const onDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const data = {
                    file: reader.result,
                    _token: tokenAttachment,
                    name: file.name,
                };
                dispatch(uploadImageSlice({ data })).then((res) => {
                    if (res.meta.requestStatus === "fulfilled") {
                        dispatch(imageTempararyData(tokenAttachment));
                    }
                });
            };
            reader.readAsDataURL(file);
        });
    };

    const onDragEnd = useCallback((result) => {
        if (!result.destination) return;
        const items = [...imageState];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setImagesState(items);
    }, [imageState]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    // Удаление изображения
    const handleDeleteImage = (id) => {
        if (localDuplicateImages.some((img) => img.id === id)) {
            // Удаляем только из локальных дубликатов
            setLocalDuplicateImages((prevImages) => prevImages.filter((img) => img.id !== id));

            // Удаляем ID из поля old_image_ids
            setDuplicateApplicationData((prevData) => ({
                ...prevData,
                old_image_ids: prevData.old_image_ids.filter((imageId) => imageId !== id)
            }));
        } else {
            // Удаляем серверное изображение
            dispatch(imageDelete(id)).then((resp) => {
                if (resp.meta.requestStatus === "fulfilled") {
                    setImagesState((prevImages) => prevImages.filter((img) => img.id !== id));
                }
            });
        }
    };

    const combinedImages = [...localDuplicateImages, ...imageState];

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="inner-item-title">Фотографии</div>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-wrap flex-row">
                        <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p>{isDragActive ? "Drop the files here ..." : "Загрузить фото"}</p>
                        </div>
                        <Gallery>
                            {combinedImages.map((imageSingle, index) => (
                                <Item
                                    key={imageSingle.id}
                                    original={imageSingle.url}
                                    thumbnail={imageSingle.thumbnail_url}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <Draggable key={imageSingle.id} draggableId={imageSingle.url} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="page-file-item"
                                                >
                                                    <img
                                                        src={imageSingle.url}
                                                        alt={`Image ${index + 1}`}
                                                        className="image-preview"
                                                        ref={ref}
                                                    />
                                                    <div className="page-file__option">
                                                        <button
                                                            type="button"
                                                            className="page-file__zoom"
                                                            onClick={open}
                                                        ></button>
                                                        <button
                                                            type="button"
                                                            className="page-file__delete"
                                                            onClick={() => handleDeleteImage(imageSingle.id)}
                                                        ></button>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                </Item>
                            ))}
                        </Gallery>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CarImageUploader;
