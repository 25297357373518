import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const FileUploadForm = ({ setDuplicateApplicationData }) => {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  // Очищаем URL при удалении файлов или размонтировании компонента
  useEffect(() => {
    return () => {
      previewUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [previewUrls]);


  // Обработчик нажатия на кнопку
  function handleClick() {
    fileInputRef.current.click();
  }

  // Добавление файлов с проверкой на уникальность
  const handleFileInputChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    const uniqueFiles = selectedFiles.filter((newFile) => {
      return !files.some((existingFile) => existingFile.name === newFile.name);
    });

    if (uniqueFiles.length === 0) return;

    const newPreviewUrls = uniqueFiles.map((file) => URL.createObjectURL(file));

    setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
  };


  // Добавление файлов в состояние приложения
  useEffect(() => {
    if (files.length > 0) {
      const serializedFiles = files.map((file) => ({
        name: file.name,
        url: URL.createObjectURL(file),
      }));

      setDuplicateApplicationData((prevData) => ({
        ...prevData,
        docs: [
          ...(prevData.docs || []),
          ...serializedFiles.filter((file) =>
              !(prevData.docs || []).some((doc) => doc.name === file.name)
          )
        ],
      }));
    }
  }, [files]);


  // Удаление файла
  const failDelete = (name) => {
    const updatedFiles = files.filter((item) => item.name !== name);
    setFiles(updatedFiles);

    const updatedUrls = previewUrls.filter((_, index) => files[index]?.name !== name);
    setPreviewUrls(updatedUrls);

    setDuplicateApplicationData((prevData) => ({
      ...prevData,
      docs: prevData.docs.filter((doc) => doc.name !== name),
    }));
  };


  return (
      <>
        <div className="inner-item-title">Паспорт, доверенность и прочее</div>
        <div>
          <input
              type="file"
              multiple
              onChange={handleFileInputChange}
              ref={fileInputRef}
              className="d-none"
          />
          <div className="page-file-list">
            <div
                className="page-add-file no-ajax upload-file"
                onClick={handleClick}
            >
              <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    opacity="0.6"
                    d="M20.0013 6.6665C20.9218 6.6665 21.668 7.4127 21.668 8.33317V18.3332H31.668C32.5884 18.3332 33.3346 19.0794 33.3346 19.9998C33.3346 20.9203 32.5884 21.6665 31.668 21.6665H21.668V31.6665C21.668 32.587 20.9218 33.3332 20.0013 33.3332C19.0808 33.3332 18.3346 32.587 18.3346 31.6665V21.6665H8.33464C7.41416 21.6665 6.66797 20.9203 6.66797 19.9998C6.66797 19.0794 7.41416 18.3332 8.33464 18.3332H18.3346V8.33317C18.3346 7.4127 19.0808 6.6665 20.0013 6.6665Z"
                    fill="#536E9B"
                />
              </svg>
            </div>

            {previewUrls.length > 0 && (
                <div className="page-file-list">
                  {previewUrls.map((previewUrl, index) => (
                      <div className="page-file-item" key={index}>
                        <div className="file-icon pdf-icon"></div>
                        <span>{files[index]?.name || "Файл"}</span>
                        <div className="page-file__option">
                          <button
                              type="button"
                              className="page-file__delete"
                              onClick={() => failDelete(files[index]?.name)}
                          ></button>
                        </div>
                      </div>
                  ))}
                </div>
            )}
          </div>
        </div>
      </>
  );
};

export default FileUploadForm;
