import React, {useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import {setDuplicateApplicationData} from "../../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";

import {
    applicationsBrand,
    brandReset,
} from "../../../../store/applications/applications-select/application-brand/applicationBrandSlice";
import {
    applicationsGenerations,
    generationReset,
} from "../../../../store/applications/applications-select/application-generations/applicationGenerationsSlice";
import {
    applicationsModel,
    modelReset,
} from "../../../../store/applications/applications-select/application-model/applicationModelSlice";
import {
    applicationsSeries,
    seriesReset,
} from "../../../../store/applications/applications-select/application-series/applicationSeriesSlice";
import {
    applicationsYear,
    yearReset,
} from "../../../../store/applications/applications-select/application-year/applicationYearSlice";
import {
    applicationsEngines,
    enginesReset,
} from "../../../../store/applications/applications-select/applications-engines/applicationEnginesSlice";
import {
    applicationsGears,
    gearsReset,
} from "../../../../store/applications/applications-select/applications-gears/applicationGearsSlice";
import {
    applicationsModifications,
    modificationsReset,
} from "../../../../store/applications/applications-select/applications-modification/applicationModificationsSlice";
import {
    applicationsTransmissions,
    transmissionsReset,
} from "../../../../store/applications/applications-select/applications-transmission/applicationTransmissionsSlice";
import {pts} from "../../../../utils/select-array";
import selectStyle from "../../../../utils/select-styles";
import FileUploadForm from "../../../file-upload/file-upload";
import ImageUploader from "../../../image-upload/image-upload";
import {
    applicationCarTypes,
} from "../../../../store/applications/applications-create/application-create-get/applicationCreateSlice";
import ImageUploaderEdit from "../../../image-upload/image-edit-upload";
import CarImageUploader from "../../../image-upload/image-upload-car";
import FileUploadModal from "../../../file-upload/file-upload-modal";
import {toast} from "react-toastify";

function ApplicationCrtUptStep2(
    {
        colors,
        tab,
        selectedOption,
        selectedPartner,
        duplicateApplicationData,
        setDuplicateApplicationData,
        getNextStepData,
        setReplace,
        replace,
        carTypesList
    }) {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = useParams()
    const {
        applicationBrand: { brand },
        applicationModel: { model },
        applicationYear: { year },
        applicationGenerations: { generations },
        applicationSeries: { series },
        applicationModifications: { modifications },
        applicationEngines: { engines },
        applicationTransmissions: { transmissions },
        applicationGears: { gears },
    } = useSelector((state) => state)

    const {user} = useSelector((state) => state.auth)
    const [subType, setSubType] = useState(0)
    const [toggleTrailerPrices, setToggleTrailerPrices] = useState(false)
    const [undefinedFieldName, setUndefinedFieldName] = useState([])

    const [searchType, setSearchType] = useState("")
    const [searchMark, setSearchMark] = useState("")
    const [searchModel, setSearchModel] = useState("")
    const [searchYear, setSearchYear] = useState("")
    const [carSeriesId, setCarSeriesId] = useState(0)
    const [carModificationId, setCarModificationId] = useState(0)
    const [imageComponent, setImageComponent] = useState({
       component: <ImageUploader/>
    })
    const [fileComponent, setFileComponent] = useState({
        component: <FileUploadForm/>
    })
    // Тех.состояние
    const [checkboxStates, setCheckboxStates] = useState({
        condition_engine: [],
        condition_transmission: [],
        condition_electric: [],
        condition_gear: [],
    })

    useEffect(() => {
        if (tab === 1) {
            if (duplicateApplicationData.year && !duplicateApplicationData?.car_modification_id){
                toast.warning('Пожалуйста заполните все поля из раздела Поколение и модификация')
            }
        }
    }, [tab])
    useEffect(()=>{
        if (tab === 1){
            if (duplicateApplicationData?.condition_engine) {
                setCheckboxStates((prevState) => ({
                    ...prevState,
                    condition_engine: duplicateApplicationData?.condition_engine,
                }))
            }
            if (duplicateApplicationData?.condition_transmission) {
                setCheckboxStates((prevState) => ({
                    ...prevState,
                    condition_transmission: duplicateApplicationData?.condition_transmission,
                }))
            }
            if (duplicateApplicationData?.condition_electric) {
                setCheckboxStates((prevState) => ({
                    ...prevState,
                    condition_electric: duplicateApplicationData?.condition_electric,
                }))
            }
            if (duplicateApplicationData?.condition_gear) {
                setCheckboxStates((prevState) => ({
                    ...prevState,
                    condition_gear: duplicateApplicationData?.condition_gear,
                }))
            }else {
                setCheckboxStates((prevState) => ({
                    ...prevState
                }))
            }
        }
    }, [tab])
    useEffect(()=>{
        if (tab === 1){
            if (replace && duplicateApplicationData?.images && duplicateApplicationData?.images.length !== 0){
                setImageComponent(()=> ({component:  <CarImageUploader
                        duplicateImages={duplicateApplicationData?.images}
                        setDuplicateApplicationData={setDuplicateApplicationData}
                    /> }))
            }
            else if (!replace && duplicateApplicationData?.images && duplicateApplicationData?.images.length !== 0){
                setImageComponent(()=> ({component:  <ImageUploaderEdit
                    oldimage={duplicateApplicationData?.images}
                    id={duplicateApplicationData.id}
                /> }))
            }
            else {
                setImageComponent(()=> ({component:  <ImageUploader/> }))
            }
        }
    }, [tab])
    useEffect(()=>{
        if (tab === 1){
            if (duplicateApplicationData?.docs && duplicateApplicationData?.docs.length !== 0){
                setFileComponent(()=> ({component:  <FileUploadModal
                        docs={duplicateApplicationData?.docs}
                        applicationId={duplicateApplicationData.id}
                    /> }))
            }
            else {
                setFileComponent(()=> ({component:  <FileUploadForm setDuplicateApplicationData={setDuplicateApplicationData} /> }))
            }
        }
    }, [tab])

    const setUndefinedFieldHandler = (e) => {
        const {name} = e.target
        setUndefinedFieldName((prevState) => {
            if (prevState.includes(name)) {
                return prevState.filter((item) => item !== name)
            } else {
                return [...prevState, name]
            }
        })
    }
    // reset car data fields
    useEffect(() => {
        const isApplicationPage = location.pathname.includes('applications') ||
            location.pathname.includes('create');

        if (!isApplicationPage) return;

        setDuplicateApplicationData(prevData => ({
            ...prevData,
            car_type_id: 0,
        }));
        // Оптимизация сброса данных
        const resetActions = [
            brandReset,
            modelReset,
            yearReset,
            generationReset,
            seriesReset,
            modificationsReset,
            enginesReset,
            transmissionsReset,
            gearsReset
        ];

        resetActions.forEach(action => dispatch(action()))
    }, [location.pathname])
    useEffect(()=>{
        if(!carSeriesId) return
        setDuplicateApplicationData((prevData) => ({
            ...prevData,
            car_series_id: carSeriesId || null
        }));
    },[carSeriesId])


    // формируем список полей которые нужно обнулить
    const getResetFields = (fieldName) => {
        const resetMap = {
            car_type_id: [
                "car_mark_id", "car_model_id", "year", "car_generation_id", "car_series_id",
                "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_mark_id: [
                "car_model_id", "year", "car_generation_id", "car_series_id",
                "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_model_id: [
                "year", "car_generation_id", "car_series_id",
                "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            year: [
                "car_generation_id", "car_series_id", "car_modification_id", "car_engine_id",
                "car_transmission_id", "car_gear_id", "car_generation", "car_series",
                "car_modification", "car_engine", "car_transmission","car_gear"
            ],
            car_series_id: [
                "car_generation_id", "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_generation_id: [
                "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_modification_id: [
                "car_engine_id", "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_engine_id: [
                "car_transmission_id", "car_gear_id",
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_transmission_id: [
                "car_gear_id", "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_gear_id: [
                "car_mark", "car_model", "car_generation", "car_series", "car_modification",
                "car_engine", "car_transmission", "car_gear"
            ],
            car_mark: [
                "car_model_id", "year", "car_generation_id", "car_series_id",
                "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
            ],
            car_model: [
                "year", "car_generation_id", "car_series_id", "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
            ],
            car_generation: [
                "car_generation_id", "car_series_id", "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
            ],
            car_series: [
                "car_series_id", "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
            ],
            car_modification: [
                "car_modification_id", "car_engine_id", "car_transmission_id", "car_gear_id",
            ],
            car_engine: [
                "car_engine_id", "car_transmission_id", "car_gear_id",
            ],
            car_transmission: [
                "car_transmission_id", "car_gear_id",
            ],
            car_gear: [
                "car_gear_id",
            ],
        };

        return resetMap[fieldName] || []; // Если поле не в списке — ничего не сбрасываем
    }
    const doNextStep = (newValue, name) => {
        setDuplicateApplicationData(() => {
            const dynamicResetFields = getResetFields(name);
            return {
                ...duplicateApplicationData, // Берём актуальное состояние (а не prevData!)
                [name]: newValue !== undefined ? newValue : null,  // Обновляем текущее поле
                ...Object.fromEntries(dynamicResetFields.map((field) => [field, null])) // Сбрасываем нужные поля
            };
        });
    }
       console.log(duplicateApplicationData)
    const handleInputChange = (e, id=null) => {
        const {name, value, type, checked} = e.target ? e.target : e;
        //у прицепов id = 4
        if (name === 'car_type_id') {
            setSubType(carTypesList.filter(carType => carType.id === value))
            setToggleTrailerPrices(true)

            let data = {
                parking_id: selectedOption.value,
                partner_id: selectedPartner.value,
                partnerUser: false
            }
            if (user.role === 'Partner') {
                data = {
                    parking_id: selectedOption.value,
                    partner_id: user.id,
                    partnerUser: true
                }
            }
            dispatch(applicationCarTypes(data))
        }
        let newValue = value;
        if (type === "checkbox") {
            newValue = checked ? 1 : 0;
        }
        if (name === "car_type_id") {
            getNextStepData(applicationsBrand(value))
            doNextStep(newValue, name)
        }
        if (name === "car_mark_id") {
            getNextStepData(applicationsModel(value))
            doNextStep(newValue, name)
        }
        if (name === "car_model_id") {
            getNextStepData(applicationsYear(value))
            doNextStep(newValue, name)
        }
        if (name === "year") {
            getNextStepData(applicationsSeries({
                year: value,
                modelsId: duplicateApplicationData?.car_model_id,
            }))
            doNextStep(value, name)
        }
        if (name === "car_series_id") {
            getNextStepData(applicationsGenerations({
                modelId: duplicateApplicationData?.car_model_id,
                year: duplicateApplicationData?.year,
                seriesId: value
            }))
            // doNextStep(newValue, name)
        }
        if (name === "car_generation_id") {
            getNextStepData(applicationsModifications({
                modelId: duplicateApplicationData?.car_model_id,
                seriesId: id,
                yearId: duplicateApplicationData?.year,
            }))
            setCarSeriesId(id)
            doNextStep(newValue, name)

        }
        if (name === "car_modification_id") {
            getNextStepData(applicationsEngines(value))
            setCarModificationId(value)
            doNextStep(newValue, name)
        }
        if (name === "car_engine_id") {
            getNextStepData(applicationsTransmissions(carModificationId))
            doNextStep(newValue, name)
        }
        if (name === "car_transmission_id") {
            getNextStepData(applicationsGears(duplicateApplicationData.car_modification_id))
            doNextStep(newValue, name)
        }
        if (name === "car_gear_id") {
            doNextStep(newValue, name)
        }
        if (name === "car_mark") {
            doNextStep(newValue, name)
        }
        if (name === "car_model") {
            doNextStep(newValue, name)
        }
        if (name === "car_generation") {
            doNextStep(newValue, name)
        }
        if (name === "car_series") {
            doNextStep(newValue, name)
        }
        if (name === "car_modification") {
            doNextStep(newValue, name)
        }
        if (name === "car_engine") {
            doNextStep(newValue, name)
        }
        if (name === "car_transmission") {
            doNextStep(newValue, name)
        }
        if (name === "car_gear") {
            doNextStep(newValue, name)
        }
        if (name === "car_key_quantity") {
            doNextStep(parseInt(newValue), name)
            return
        }
        if (name === "owner_number") {
            doNextStep(parseInt(newValue), name)

        }
        else {
            doNextStep(newValue, name)
        }
    }

    const handleCheckboxChange = (name, value) => {
        setCheckboxStates((prevState) => {
            if (Array.isArray(prevState[name])) {
                // Если значение уже является массивом
                if (prevState[name].includes(value)) {
                    // Удаляем значение из массива
                    return {
                        ...prevState,
                        [name]: prevState[name].filter((item) => item !== value),
                    };
                } else {
                    // Добавляем значение в массив
                    return {
                        ...prevState,
                        [name]: [...prevState[name], value],
                    };
                }
            } else {
                // Если значение не является массивом
                return {
                    ...prevState,
                    [name]: [value],
                }
            }
        })
    }
    useEffect(() => {
        setDuplicateApplicationData(() => {
            return {
                ...duplicateApplicationData, // Берём актуальное состояние (а не prevData!)
                ['condition_engine']: checkboxStates.condition_engine.length !== 0 ? checkboxStates.condition_engine : []  || null, // Обновляем текущее поле
                ['condition_transmission']: checkboxStates.condition_transmission.length !== 0 ? checkboxStates.condition_transmission : []  || null, // Обновляем текущее поле
                ['condition_electric']: checkboxStates.condition_electric.length !== 0 ? checkboxStates.condition_electric : []  || null, // Обновляем текущее поле
                ['condition_gear']: checkboxStates.condition_gear.length !== 0 ? checkboxStates.condition_gear : []  || null, // Обновляем текущее поле
            };
        });

    }, [checkboxStates])
    const filterBySearchedData = (e) => {
        const {value, name} = e.target.target
        switch (name) {
            case "car-type-search":
                setSearchType(value);
                break;
            case "car-mark-search":
                setSearchMark(value);
                break;
            case "car-model-search":
                setSearchModel(value);
                break;
            case "year-search":
                setSearchYear(value);
                break;
        }
    }
    const ownerOptions = [
        { value: 1, label: "Первый" },
        { value: 2, label: "Второй" },
        { value: 3, label: "Третий и более" }
    ];
    return (
        <div className="row no-gutters " id="v-pills-2">
            <div className={'prise_parking'}>
                <div>
                    Стоянка: {selectedOption.label}
                </div>
                {toggleTrailerPrices &&
                <div className={'prise_parking__right'}>
                    <span>Стоимость:</span>
                    <div className="d-flex radio-check-list">
                        {subType[0].car_subtypes ? subType[0].car_subtypes.map((subTypeItem, index) => {
                                return <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap custom_radio">
                                        <input
                                            type="radio"
                                            name="car_subtype_id"
                                            defaultValue={parseInt(subTypeItem.id)}
                                            defaultChecked={index === 2 ? true : false}
                                            onChange={handleInputChange}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>{subTypeItem.name}: {subTypeItem.price}руб.</span>
                                    </label>
                                </div>
                            }) :
                            <div className="mt-2 mb-3">
                                <label className="switch-radio-wrap custom_radio">

                                    <span> {subType[0].price}руб.</span>
                                </label>
                            </div>
                        }
                    </div>
                </div>
                }

            </div>
            <div className="col-md-12">
                {/* Марка и модель */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Марка и модель</div>
                    <div className="row mr-offset-20">
                        <div className="col-12">
                            <div className="tabform__cartlist w-100 d-flex">
                                {/* Тип Авто */}
                                <fieldset
                                    className="tabform__cart select first-cart car_type_id fieldset new-style-model">
                                    <legend className="legend">
                                        Тип авто...
                                        <span className="mob-arrow"></span>
                                    </legend>
                                    <div className="tabform__mob-dd type-card">
                                        <input
                                            type="text"
                                            name={'car-type-search'}
                                            placeholder="Поиск"
                                            className="select-search"
                                            onChange={(e) => filterBySearchedData(e)}
                                        />
                                        <ul className="select-list tabform__ul type-list">
                                            {carTypesList &&
                                            carTypesList
                                                .filter((item) =>
                                                    item.id !== 27 &&
                                                    item.name
                                                        .toLowerCase()
                                                        .includes(searchType.toLowerCase())
                                                )
                                                .map((item) => (
                                                    <li
                                                        className={`select-item tabform__li ${
                                                            duplicateApplicationData.car_type_id === item.id ? "active" : ""
                                                        }`}
                                                        key={item.id}
                                                        onClick={() => {
                                                            handleInputChange({
                                                                name: "car_type_id",
                                                                value: item.id,
                                                            });
                                                        }}
                                                    >
                                                        <div>{item.name}</div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </fieldset>
                                <>
                                    {/* Марка авто */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('mark') ? (
                                            <label className="field-style">
                                                <span>Марка авто</span>
                                                <input
                                                    type="text"
                                                    name="car_mark"
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset className="tabform__cart select car_mark_id fieldset ">
                                                <legend className="legend">
                                                    Марка авто <span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        name={'car-mark-search'}
                                                        placeholder="Поиск"
                                                        className="select-search"
                                                        onChange={(e) => filterBySearchedData(e)}
                                                    />
                                                    <ul className="tabform__ul select-list type-list">
                                                        {brand && brand.length !== 0 ? (
                                                            brand
                                                                .filter((item) =>
                                                                    item.name
                                                                        .toLowerCase()
                                                                        .includes(searchMark.toLowerCase())
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            className={`select-item tabform__li ${
                                                                                duplicateApplicationData?.car_mark_id === item.id ? "active" : ""
                                                                            }`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                handleInputChange({
                                                                                    name: "car_mark_id",
                                                                                    value: item.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <div>{item.name}</div>
                                                                        </li>
                                                                    );
                                                                })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите марку авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            <input
                                                className="checkbox-unknown cvin"
                                                type="checkbox"
                                                name="mark"
                                                onChange={setUndefinedFieldHandler}
                                            />
                                            <span className="switcher-radio"></span>
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/* Модель авто */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('model') ? (
                                            <label className="field-style">
                                                <span>Модель авто</span>
                                                <input
                                                    type="text"
                                                    name="car_model"
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset className="tabform__cart select car_model_id fieldset ">
                                                <legend className="legend">
                                                    Модель авто <span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        name={'car-model-search'}
                                                        placeholder="Поиск"
                                                        className="select-search"
                                                        onChange={(e) => filterBySearchedData(e)}
                                                    />
                                                    <ul
                                                        className="select-list tabform__ul type-list"
                                                        data-placeholder="Выберите марку авто"
                                                    >
                                                        {model && model.length !== 0 ? (
                                                            model
                                                                .filter((item) =>
                                                                    item.name
                                                                        .toLowerCase()
                                                                        .includes(searchModel.toLowerCase())
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            className={`select-item tabform__li ${
                                                                                duplicateApplicationData.car_model_id === item.id ||
                                                                                model.length === 1
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                handleInputChange({
                                                                                    name: "car_model_id",
                                                                                    value: item.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <div>{item.name}</div>
                                                                        </li>
                                                                    );
                                                                })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите модель авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('mark') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="model"
                                                        onChange={setUndefinedFieldHandler}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                </>
                                            )}

                                            <span> Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/* Год авто */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ? (
                                            <label className="field-style">
                                                <span>Год</span>
                                                <input
                                                    type="text"
                                                    name="year"
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset className="tabform__cart select year fieldset ">
                                                <legend className="legend">
                                                    Год авто.<span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        name={'year-search'}
                                                        placeholder="Поиск"
                                                        onChange={(e) => filterBySearchedData(e)}
                                                        className="select-search"
                                                    />
                                                    <ul className="select-list tabform__ul type-list">
                                                        {year && year.length !== 0 ? (
                                                            year
                                                                .filter((item) =>
                                                                    item.name
                                                                        .toString()
                                                                        .toLowerCase()
                                                                        .includes(searchYear.toLowerCase())
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            className={`select-item tabform__li ${
                                                                                duplicateApplicationData.year === item.id ||
                                                                                year.length === 1 ? "active" : ""
                                                                            }`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                handleInputChange({
                                                                                    name: "year",
                                                                                    value: item.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <div>{item.name}</div>
                                                                        </li>
                                                                    );
                                                                })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите год авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="year"
                                                        onChange={setUndefinedFieldHandler}

                                                    />
                                                    <span className="switcher-radio"></span>
                                                </>)}
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Поколение и модификация */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Поколение и модификация</div>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="tabform__cartlist tabform__cartlist-col-3
                                        d-flex justify-content-between gap-4 no_margin cstm-height">
                                {/* Кузов  */}
                                <div className="car__mark-with-undefined new-style-model">
                                    {undefinedFieldName?.includes('model') ||
                                    undefinedFieldName?.includes('mark') ||
                                    undefinedFieldName?.includes('year') ||
                                    undefinedFieldName?.includes('car_generation') ||
                                    undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style">
                                            <span>Кузов...</span>
                                            <input
                                                type="text"
                                                name="car_series"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset className="tabform__cart select car_mark_id fieldset">
                                            <legend className="legend">
                                                Кузов...<span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />
                                                <ul className="select-list tabform__ul type-list">
                                                    {series && series.length !== 0 ? (
                                                        series.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        duplicateApplicationData?.car_series_id === item.id ||
                                                                        series.length === 1 ? "active" : ""}`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleInputChange({
                                                                            name: "car_series_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите кузов авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>
                                    )}
                                    <label className="switch-radio-wrap mt-2">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('year') ||
                                        undefinedFieldName?.includes('car_generation') ? '' : (
                                            <>
                                                <input
                                                    className="checkbox-unknown cvin"
                                                    type="checkbox"
                                                    name="car_series"
                                                    onChange={setUndefinedFieldHandler}
                                                />
                                                <span className="switcher-radio"></span>
                                            </>)}
                                        <span>Ввести в ручную</span>
                                    </label>
                                </div>
                                {/* Поколение  */}
                                <div className="car__mark-with-undefined new-style-model">
                                    {undefinedFieldName?.includes('model') ||
                                    undefinedFieldName?.includes('mark') ||
                                    undefinedFieldName?.includes('year') ||
                                    undefinedFieldName?.includes('car_generation') ? (
                                        <label className="field-style">
                                            <span>Поколение</span>
                                            <input
                                                type="text"
                                                name="car_generation"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset className="tabform__cart select car_mark_id fieldset">
                                            <legend className="legend">
                                                Поколение.. <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />
                                                <ul className="select-list tabform__ul type-list">
                                                    {generations &&
                                                    generations.length !== 0 &&
                                                    generations !== 0 ? (
                                                        generations.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        duplicateApplicationData.car_generation_id === item.id ||
                                                                        generations.length === 1 ? "active" : ""}`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleInputChange({
                                                                            name: "car_generation_id",
                                                                            value: item.id,
                                                                        }, item.car_serie_id);
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите поколение авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>
                                    )}
                                    <label className="switch-radio-wrap mt-2">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('year') ? '' : (
                                            <>
                                                <input
                                                    className="checkbox-unknown cvin"
                                                    type="checkbox"
                                                    name="car_generation"
                                                    onChange={setUndefinedFieldHandler}
                                                />
                                                <span className="switcher-radio"></span>
                                            </>)}
                                        <span>Ввести в ручную</span>
                                    </label>
                                </div>
                                {/* Модификация */}
                                <div className="car__mark-with-undefined new-style-model">
                                    {undefinedFieldName?.includes('model') ||
                                    undefinedFieldName?.includes('mark') ||
                                    undefinedFieldName?.includes('year') ||
                                    undefinedFieldName?.includes('car_generation') ||
                                    undefinedFieldName?.includes('car_modification') ||
                                    undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style ">
                                            <span>Модификация...</span>
                                            <input
                                                type="text"
                                                name="car_modification"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset className="tabform__cart select car_mark_id fieldset"
                                                  id="modifications"
                                        >
                                            <legend className="legend">
                                                Модификация... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />
                                                <ul className="select-list tabform__ul type-list">
                                                    {modifications && modifications.length !== 0 ? (
                                                        modifications.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        duplicateApplicationData?.car_modification_id === item.id ||
                                                                        modifications.length === 1 ? "active" : ""}`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleInputChange({
                                                                            name: "car_modification_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите модификацию авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>)}
                                    <label className="switch-radio-wrap mt-2">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_series') ? '' : (
                                            <>
                                                <input
                                                    className="checkbox-unknown cvin"
                                                    type="checkbox"
                                                    name="car_modification"
                                                    onChange={setUndefinedFieldHandler}
                                                />
                                                <span className="switcher-radio"></span>
                                            </>)}
                                        <span>Ввести в ручную</span>
                                    </label>
                                </div>
                                {/* Двигатель */}
                                <div className="car__mark-with-undefined new-style-model">
                                    {undefinedFieldName?.includes('model') ||
                                    undefinedFieldName?.includes('mark') ||
                                    undefinedFieldName?.includes('year') ||
                                    undefinedFieldName?.includes('car_generation') ||
                                    undefinedFieldName?.includes('car_modification') ||
                                    undefinedFieldName?.includes('car_engine') ||
                                    undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style ">
                                            <span>Двигатель...</span>
                                            <input
                                                type="text"
                                                name="car_engine"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset
                                            className="tabform__cart select engines fieldset"
                                            id="engines"
                                        >
                                            <legend className="legend">
                                                Двигатель... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />

                                                <ul
                                                    className="select-list tabform__ul type-list"
                                                    data-placeholder="Выберите двигатель авто"
                                                >
                                                    {engines && engines.length !== 0 ? (
                                                        engines.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        duplicateApplicationData?.car_engine_id === item.id ||
                                                                        engines.length === 1 ? "active" : ""}`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleInputChange({
                                                                            name: "car_engine_id",
                                                                            value: item.id,
                                                                        })
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите двигатель авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>)}
                                    <label className="switch-radio-wrap mt-2">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_series') ? '' : (
                                            <>
                                                <input
                                                    className="checkbox-unknown cvin"
                                                    type="checkbox"
                                                    name="car_engine"
                                                    onChange={setUndefinedFieldHandler}
                                                />
                                                <span className="switcher-radio"></span>
                                            </>)}
                                        <span>Ввести в ручную</span>
                                    </label>
                                </div>
                                {/* КПП */}
                                <div className="car__mark-with-undefined new-style-model">
                                    {undefinedFieldName?.includes('model') ||
                                    undefinedFieldName?.includes('mark') ||
                                    undefinedFieldName?.includes('year') ||
                                    undefinedFieldName?.includes('car_generation') ||
                                    undefinedFieldName?.includes('car_modification') ||
                                    undefinedFieldName?.includes('car_engine') ||
                                    undefinedFieldName?.includes('car_transmission') ||
                                    undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style ">
                                            <span>КПП...</span>
                                            <input
                                                type="text"
                                                name="car_transmission"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset
                                            className="tabform__cart select transmissions fieldset"
                                            id="transmissions"
                                        >
                                            <legend className="legend">
                                                КПП... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />

                                                <ul
                                                    className="select-list tabform__ul type-list"
                                                    data-placeholder="Выберите двигатель авто"
                                                >
                                                    {transmissions && transmissions.length !== 0 ? (
                                                        transmissions.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        duplicateApplicationData?.car_transmission_id === item.id ||
                                                                        transmissions.length === 1 ? "active" : ""}`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleInputChange({
                                                                            name: "car_transmission_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите КПП авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>)}
                                    <label className="switch-radio-wrap mt-2">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_engine') ||
                                        undefinedFieldName?.includes('car_series') ? '' : (
                                            <>
                                                <input
                                                    className="checkbox-unknown cvin"
                                                    type="checkbox"
                                                    name="car_transmission"
                                                    onChange={setUndefinedFieldHandler}
                                                />
                                                <span className="switcher-radio"></span>
                                            </>)}
                                        <span>Ввести в ручную</span>
                                    </label>
                                </div>
                                {/* Привод  */}
                                <div className="car__mark-with-undefined new-style-model">
                                    {undefinedFieldName?.includes('model') ||
                                    undefinedFieldName?.includes('mark') ||
                                    undefinedFieldName?.includes('year') ||
                                    undefinedFieldName?.includes('car_generation') ||
                                    undefinedFieldName?.includes('car_modification') ||
                                    undefinedFieldName?.includes('car_engine') ||
                                    undefinedFieldName?.includes('car_transmission') ||
                                    undefinedFieldName?.includes('car_gear') ||
                                    undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style ">
                                            <span>Привод...</span>
                                            <input
                                                type="text"
                                                name="car_gear"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset
                                            className="tabform__cart select gears fieldset"
                                            id="gears"
                                        >
                                            <legend className="legend">
                                                Привод... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />
                                                <ul
                                                    className="select-list tabform__ul type-list"
                                                    data-placeholder="Выберите двигатель авто"
                                                >
                                                    {gears && gears.length !== 0 ? (
                                                        gears.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        duplicateApplicationData?.car_gear_id === item.id ||
                                                                        gears.length === 1 ? "active" : ""}`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleInputChange({
                                                                            name: "car_gear_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите привод авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>)}
                                    <label className="switch-radio-wrap mt-2">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_engine') ||
                                        undefinedFieldName?.includes('car_transmission') ||
                                        undefinedFieldName?.includes('car_series') ? '' : (
                                            <>
                                                <input
                                                    className="checkbox-unknown cvin"
                                                    type="checkbox"
                                                    name="car_gear"
                                                    onChange={setUndefinedFieldHandler}
                                                />
                                                <span className="switcher-radio"></span>
                                            </>)}
                                        <span>Ввести в ручную</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                {/* Документы */}
                {user.role !== 'Partner' && (
                    <div className="inner-page__item">
                        <div className="inner-item-title">Документы</div>
                        <div className="row">
                            <div className="col-6">
                                <label className="field-style">
                                    <span>СТС</span>
                                    <input
                                        type="text"
                                        name="sts"
                                        defaultValue={duplicateApplicationData?.sts}
                                        onChange={handleInputChange}
                                        placeholder="Не указан"
                                    />
                                </label>
                                {user.role !== "Operator" && (
                                    <div className="mt-2">
                                        <label className="switch-radio-wrap">
                                            <input
                                                type="checkbox"
                                                name="sts_provided"
                                                value="1"
                                                onChange={handleInputChange}
                                                checked={duplicateApplicationData?.sts_provided ? true : false}
                                            />
                                            <span className="switcher-radio"></span>
                                            <span>Принят на хранение</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-6">
                                <div className="d-flex two-field justify-content-start">
                                    <label className="field-style w-100 m-0">
                                        <span>ПТС</span>
                                        <input
                                            onChange={handleInputChange}
                                            name="pts"
                                            type="text"
                                            defaultValue={duplicateApplicationData?.pts}
                                            placeholder="Не указан"
                                            className="w-100"
                                        />
                                    </label>
                                    <label className="field-style select-react__style">
                                        <Select
                                            value={pts.find(option => option.value === duplicateApplicationData?.pts_type) || null}
                                            options={pts}
                                            styles={selectStyle}
                                            name="pts_type"
                                            className="page-select"
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>
                                {user.role !== "Operator" && (
                                    <div className="mt-2">
                                        <label className="switch-radio-wrap">
                                            <input
                                                type="checkbox"
                                                name="pts_provided"
                                                value="1"
                                                onChange={handleInputChange}
                                                checked={duplicateApplicationData?.pts_provided ? true : false}
                                            />
                                            <span className="switcher-radio"></span>
                                            <span>Принят на хранение</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {/* Информация об автомобиле */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Информация об автомобиле</div>
                    <div className="row">
                        <div className="col-6">
                            <div className="inner-page__item-title">Кол-во владельцев</div>
                            <div className="d-flex radio-check-list">
                                {ownerOptions.map(({ value, label }) => (
                                    <div key={value} className="mt-2 mb-3">
                                        <label className="switch-radio-wrap">
                                            <input
                                                type="radio"
                                                name="owner_number"
                                                value={value}
                                                onChange={handleInputChange}
                                                checked={duplicateApplicationData?.owner_number === value}
                                            />
                                            <span className="switcher-radio"></span>
                                            <span>{label}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="inner-page__item-title">Кол-во ключей</div>
                            <div className="d-flex radio-check-list">
                                    {[0, 1, 2, 3].map((value) => (
                                        <div key={value} className="mt-2 mb-3">
                                            <label  className="switch-radio-wrap">
                                                <input
                                                    type="radio"
                                                    name="car_key_quantity"
                                                    defaultValue={value}
                                                    onChange={handleInputChange}
                                                    checked={duplicateApplicationData?.car_key_quantity === value}
                                                />
                                                <span className="switcher-radio"></span>
                                                <span>{value}</span>
                                            </label>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-6">
                            <label className="field-style  select-react__style">
                                <span>Цвет</span>
                                <Select
                                    value={colors.find(option => option.value === duplicateApplicationData?.color) || null}
                                    options={colors}
                                    styles={selectStyle}
                                    className="page-select"
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="field-style mileage">
                                <span>Пробег</span>
                                <input
                                    type="number"
                                    name="milage"
                                    placeholder="Не указан"
                                    value={duplicateApplicationData?.milage || ''}
                                    onChange={handleInputChange}
                                />
                                <span className="mileage-type">км</span>
                            </label>
                        </div>
                    </div>
                </div>
                {/* Тех. состояние */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Тех. состояние</div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row radio-check-list">
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_engine"
                                            defaultValue="null"
                                            className="chech-dd"
                                            checked={checkboxStates.condition_engine.includes("null")}
                                            onChange={() => {
                                                if (checkboxStates.condition_engine.includes("null")) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_engine: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_engine: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности двигателя</span>
                                    </label>
                                    {checkboxStates.condition_engine.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Дымность двигателя (густой, белый, сизый, черный)"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Дымность двигателя (густой, белый, сизый, черный)"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Дымность двигателя (густой, белый, сизый, черный)"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Дымность двигателя (густой, белый, сизый, черный)
                                                </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Повышенный стук и шум при работе двигателя"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Повышенный стук и шум при работе двигателя"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Повышенный стук и шум при работе двигателя"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Повышенный стук и шум при работе двигателя
                                                </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Повышенный шум при работе выхлопной системы"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Повышенный шум при работе выхлопной системы"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Повышенный шум при работе выхлопной системы"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Повышенный шум при работе выхлопной системы
                                                </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Подтекание при осмотре подкапотного пространства"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Подтекание при осмотре подкапотного пространства"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Подтекание при осмотре подкапотного пространства"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Подтекание при осмотре подкапотного пространства
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_transmission"
                                            className="chech-dd"
                                            checked={checkboxStates.condition_transmission.includes(
                                                "null"
                                            )}
                                            onChange={() => {
                                                if (
                                                    checkboxStates.condition_transmission.includes("null")
                                                ) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_transmission: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_transmission: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности КПП</span>
                                    </label>
                                    {checkboxStates.condition_transmission.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_transmission"
                                                    defaultValue="Рывки и толчки авто при переключении"
                                                    checked={checkboxStates.condition_transmission.includes(
                                                        "Рывки и толчки авто при переключении"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_transmission",
                                                            "Рывки и толчки авто при переключении"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Рывки и толчки авто при переключении
                                                </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_transmission"
                                                    defaultValue="Повышенный шум при переключении"
                                                    checked={checkboxStates.condition_transmission.includes(
                                                        "Повышенный шум при переключении"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_transmission",
                                                            "Повышенный шум при переключении"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Повышенный шум при переключении
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_electric"
                                            defaultValue=""
                                            className="chech-dd"
                                            checked={checkboxStates.condition_electric.includes(
                                                "null"
                                            )}
                                            onChange={() => {
                                                if (
                                                    checkboxStates.condition_electric.includes("null")
                                                ) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_electric: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_electric: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности электрики</span>
                                    </label>
                                    {checkboxStates.condition_electric.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_electric"
                                                    defaultValue="Ошибки на панели приборов при заведенном ДВС"
                                                    checked={checkboxStates.condition_electric.includes(
                                                        "Ошибки на панели приборов при заведенном ДВС"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_electric",
                                                            "Ошибки на панели приборов при заведенном ДВС"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Ошибки на панели приборов при заведенном ДВС
                                                </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_electric"
                                                    defaultValue="Неправильные команды электроники"
                                                    checked={checkboxStates.condition_electric.includes(
                                                        "Неправильные команды электроники"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_electric",
                                                            "Неправильные команды электроники"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                  Неправильные команды электроники
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_gear"
                                            className="chech-dd"
                                            checked={checkboxStates.condition_gear.includes("null")}
                                            onChange={() => {
                                                if (checkboxStates.condition_gear.includes("null")) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_gear: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_gear: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности ходовой</span>
                                    </label>
                                    {checkboxStates.condition_gear.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_gear"
                                                    defaultValue="Посторонний звук со стороны ходовой"
                                                    checked={checkboxStates.condition_gear.includes(
                                                        "Посторонний звук со стороны ходовой"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_gear",
                                                            "Посторонний звук со стороны ходовой"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                    Посторонний звук со стороны ходовой
                                                </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_gear"
                                                    defaultValue="Посторонние звуки при вращении рулевого колеса"
                                                    checked={checkboxStates.condition_gear.includes(
                                                        "Посторонние звуки при вращении рулевого колеса"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_gear",
                                                            "Посторонние звуки при вращении рулевого колеса"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                                                    Посторонние звуки при вращении рулевого колеса
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Фотографии */}
                <div className="inner-page__item">
                    {imageComponent.component}
                </div>
                {/* Документы */}
                <div className="inner-page__item">
                    {fileComponent.component}
                </div>
                <div className="inner-page__item">
                    <div className="inner-item-title">Дополнительно</div>
                    <div className="field-style">
                        <span>Описание</span>
                        <textarea
                            name="car_additional"
                            onChange={handleInputChange}
                            placeholder="Не указан"
                            className="mw-100"
                            defaultValue={duplicateApplicationData?.car_additional}
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApplicationCrtUptStep2