import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import ApplicatonCrtUptHeader from "./applications-crt-upt-header/applications-crt-upt-header";
import ApplicationCrtUptStep1 from "./applications-crt-upt-step-1/applications-crt-upt-step-1";
import ApplicationCrtUptStep2 from "./applications-crt-upt-step-2/applications-crt-upt-step-2";

import { Nav, Tab } from "react-bootstrap";
import {
  applicationCarTypes,
  applicationsCreate
} from "../../../store/applications/applications-create/application-create-get/applicationCreateSlice";
import {
  appliationResultPost,
  appReset,
} from "../../../store/applications/applications-create/application-create-post/applicationCreatePostSlice";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { selectColor } from "../../../utils/select-array";
import {
  setFormDataReset
} from "../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";
import Preloader from "../../preloader/preloader";
import moment from "moment";
import {applicationsBrand} from "../../../store/applications/applications-select/application-brand/applicationBrandSlice";
import {applicationsModel} from "../../../store/applications/applications-select/application-model/applicationModelSlice";
import {applicationsYear} from "../../../store/applications/applications-select/application-year/applicationYearSlice";
import {applicationsGenerations} from "../../../store/applications/applications-select/application-generations/applicationGenerationsSlice";
import {applicationsSeries} from "../../../store/applications/applications-select/application-series/applicationSeriesSlice";
import {applicationsModifications} from "../../../store/applications/applications-select/applications-modification/applicationModificationsSlice";
import {applicationsEngines} from "../../../store/applications/applications-select/applications-engines/applicationEnginesSlice";
import {applicationsTransmissions} from "../../../store/applications/applications-select/applications-transmission/applicationTransmissionsSlice";
import {applicationsGears} from "../../../store/applications/applications-select/applications-gears/applicationGearsSlice";
import {
  applicationsEditGet,
  editApplicatioionReset
} from "../../../store/applications/applications-edit/applications-edit-get/applicationsEditSlice";
import {serialNumberReset} from "../../../store/applications/application-vin-dublicate/applicationVinDublicateSlice";
import {applicationsEditPut} from "../../../store/applications/applications-edit/applications-edit-put/applicaitonsEditPutSlice";

function ApplicationCrtUpt() {
  const { applications } = useSelector((state) => state.applicationCreate);
  const { serial_number } = useSelector((state) => state.applicationVinDublicate );
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()


  const [disabledBtn, setDisabledBtn] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [duplicateApplicationData, setDuplicateApplicationData] = useState({})
  const [replace, setReplace] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const [selectedOption, setSelectedOption] = useState({
      value: 0,
      label: "Выберите стоянку",
      name: "parking_id",
  })
  const [selectedPartner, setSelectedPartner] = useState({
    value: 0,
    label: "Выберите партнера",
    name: "partner_id",
  })
  //тут храним данные по дубликату авто что бы иметь возможность их редактировать
  const [emptyObjectData, setEmptyObjectData] =  useState({
    issuance_date: "",
    car_title: "",
    vin_array: "",
    license_plate: "",
    license_plate_status: 0,
    year: 0,
    car_additional: "",
    car_type_id: 0,
    car_mark_id: 0,
    car_model_id: 0,
    car_generation_id: 0,
    car_series_id: 0,
    car_modification_id: 0,
    car_engine_id: 0,
    car_transmission_id: 0,
    car_gear_id: 0,
    external_id: "",
    courier_fullname: "",
    courier_phone: "",
    owner_fullname: "",
    owner_phone: "",
    client_id: 0,
    status_id: 0,
    status: 0,
    images: [],
    arriving_at: moment().format("YYYY-MM-DD"),
    arriving_interval: '',
    partner_id: 0,
    parking_id: 0,
    condition_engine: [],
    condition_transmission: [],
    condition_electric: [],
    condition_gear: [],
    returned: 0,
    old_image_ids: [],
    old_doc_ids: [],
    docs:[],
    _token: ''
  })
  const [currentTab, setCurrentTab] = React.useState(0)
  const [token, setCurrentCarToken] = useState("")
  const [externalIdUndefined, setExternalIdUndefined] = useState(false)
  const [dataUpdateLoaded, setUpdateLoaded] = useState(false)


  useEffect(() => {
    if (applications && applications.length !== 0) {
      localStorage.setItem(
          "attachment_token",
          JSON.stringify(applications.token)
      )
      setCurrentCarToken(applications.token)
      setDuplicateApplicationData((prevData) => ({
        ...prevData,
        _token: '"' + applications.token + '"',
      }));
    }
  }, [location.pathname, applications]);
  useEffect(() => {
    dispatch(applicationsEditGet(id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setDataToCurrentCar(res)
        setUpdateLoaded(true);
      }
      else {
      }
    })
  }, [id, navigate])

  // const token = localStorage.getItem("attachment_token");

  const getNextStepData = (func) => {
    dispatch(func);
  }

  function setDataToCurrentCar (response){
    if (response) {
      // сохраняем данные по партнеру и стоянке , перед их форматированием
      setSelectedPartner(
          {
            value: response?.payload?.application.partner?.id,
            label: response?.payload?.application.partner?.name,
            name: 'partner_id'
          }
      )
      setSelectedOption(
          {
            value: response?.payload?.application.parking?.id,
            label: response?.payload?.application.parking?.title,
            name: 'parking_id'
          }
      )
      const formattedData = {
        ...response?.payload?.application,
        partner_id: response?.payload?.application?.partner?.id || null,
        parking_id: response?.payload?.application?.parking?.id || null,
        vin_array: response?.payload?.application?.vin || null,
        returned : 1,
        status_id: 2,
        status: 0,
      }
      delete formattedData.parking
      delete formattedData.partner
      // delete formattedData.id
      delete formattedData.vin
      setDuplicateApplicationData(formattedData)
      handleDuplicateData(response?.payload?.application)
    } else {
      console.log(new Error('Can not get Data by duplicated ID'))
    }
  }
  function handleDuplicateData(data) {
    if (data?.car_type_id) {
      getNextStepData(applicationsBrand(data?.car_type_id))
    }
    if (data?.car_mark_id) {
      getNextStepData(applicationsModel(data?.car_mark_id))
    }
    if (data?.car_model_id) {
      getNextStepData(applicationsYear(data?.car_model_id))
    }
    if (data?.year) {
      getNextStepData(applicationsSeries({
        year: data?.year,
        modelsId: data?.car_model_id,
      }))
    }
    if (data.car_series_id) {
      getNextStepData(applicationsGenerations({
        modelId: data?.car_model_id,
        year: data?.year,
        seriesId: data.car_series_id
      }))
    }
    if (data?.car_generation_id) {
      getNextStepData(applicationsModifications({
        modelId: data?.car_model_id,
        seriesId: data?.car_series_id,
        yearId: data?.year,
      }))
    }
    if (data?.car_modification_id) {
      getNextStepData(applicationsEngines(data.car_modification_id))
    }
    if (data?.car_engine_id) {
      getNextStepData(applicationsTransmissions(data.car_modification_id))
    }
    if (data?.car_transmission_id) {
      getNextStepData(applicationsGears(data.car_modification_id))
    }
    setDuplicateApplicationData((prevData) => ({
      ...prevData,
      car_key_quantity : data?.car_key_quantity ? data?.car_key_quantity : 1,
      owner_number : data?.owner_number ? data?.owner_number : 1,
      // arriving_at: data?.formated_arriving_at ? data?.formated_arriving_at : moment().format("YYYY-DD-MM"),
    }))
  }

  useEffect(()=> {
    dispatch(applicationsCreate()).then((res)=>{
      if (res.payload.token) {
        dispatch(setFormDataReset());
      }
      setDataLoaded(true);
    })
     // Установка флага загрузки данных
    setDuplicateApplicationData(emptyObjectData)
  },[])


  useEffect(() => {
    if (!replace) {
      setSelectedPartner (
          {
            value: 0,
            label: "Выберите партнера стоянку",
            name: "parking_id",
          }
      )
      setSelectedOption (
          {
            value: 0,
            label: "Выберите стоянку",
            name: "partner_id",
          }
      )
      setDuplicateApplicationData(emptyObjectData)
    }
    else {
      if (!serial_number || !serial_number?.vins) {
        return
      }
      if (!Array.isArray(serial_number?.vins) || serial_number?.vins.length === 0) {
        return
      }
      if (serial_number?.vins[0]?.id) {
        const {id} = serial_number?.vins[0]
        dispatch(applicationsEditGet(id)).then((response) => {
          if (response){
            setDataToCurrentCar(response)
          }
        })
      } else {
        console.log(new Error('serial_number error'))
      }
    }
  }, [replace])

  // Стили для select-react
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
      width: "160px",
      fontSize: state.selectProps.myFontSize,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  //

  // Валидация для первого таба
  const tabFucnt = (tab) => {
      if (user.role !== 'Partner'){
      if (
          duplicateApplicationData.partner_id !== "" &&
          duplicateApplicationData.parking_id !== "" &&
          (duplicateApplicationData.external_id !== ""|| externalIdUndefined) &&
          !serial_number.error
      ) {
        setCurrentTab(tab);
      }
    }else{
      if (
          duplicateApplicationData.parking_id !== "" &&
          duplicateApplicationData.parking_id &&
          duplicateApplicationData.external_id !== "" &&
          duplicateApplicationData.external_id &&
          !serial_number.error
      ) {
        setCurrentTab(tab);
      }
    }
    getCarTypes()
  };
  //


  const getCarTypes = () => {
    let data = {
      parking_id : selectedOption.value,
      partner_id : selectedPartner.value,
      partnerUser: false
    }
    if (user.role === 'Partner') {
      data = {
        parking_id: selectedOption.value,
        partner_id: user.id,
        partnerUser: true
      }
    }
    dispatch(applicationCarTypes(data))
  }

  const { isError, isSuccess, message } = useSelector(
    (state) => state.applicationResultPost
  );

  useEffect(() => {
    if (isError) {
      toast.error(message.error);
    }

    if (isSuccess) {
      navigate("/applications");
      dispatch(appReset());
    }
  }, [isError, isSuccess, message, navigate, dispatch]);

  // Создание машины
  const onCreateApplication = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    setDuplicateApplicationData(prev => {
      const { id, ...rest } = prev; // Деструктурируем объект, исключая id
      return rest; // Возвращаем новый объект без id
    });

    dispatch(appliationResultPost(duplicateApplicationData)).then((response) => {
      if (response.payload.status === 201) {
        swal("Создано!", {
          icon: "success",
        }).then(() => {
          setDisabledBtn(false);
          localStorage.removeItem("attachment_token");
          // window.location.replace("/applications");
        });
      } else {
        let errorMessage = "Ошибка при создании!";
        setDisabledBtn(false);

        if (response.payload.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  //
  // on update
  const onUpdateApplication = (e) => {
    e.preventDefault();
    dispatch(
        applicationsEditPut({
          id: id,
          data: duplicateApplicationData,
          role: user.role,
        })
    ).then((response) => {
      if (response.payload.status === 200) {
        swal("Обновлен!", {
          icon: "success",
        }).then(() => {
          window.location.replace("/applications")
        })
      } else {

        let errorMessage = "Ошибка при обновлении!"

        if (response.error.message === 'Rejected') {
          errorMessage = response.payload.message
        }
        swal(errorMessage, {
          icon: "error",
        })
      }
    })
  }
  return (
    <>
      {dataLoaded ? (
        <form
          method="POST"
          onSubmit={id ? onUpdateApplication : onCreateApplication}
          encType="multipart/form-data"
        >
          <ApplicatonCrtUptHeader
            tab={currentTab}
            tabfunc={tabFucnt}
            title={applications.title}
            status={applications.statuses}
            token={token}
            styles={styles}
            disabledBtn={disabledBtn}
            duplicateApplicationData={duplicateApplicationData}
            setDuplicateApplicationData={setDuplicateApplicationData}
            id={id}
          />
          <div className="container">
            <div className="inner-page">
              <div className="row no-gutters position-relative">
                <div className="tab-content tab-content-main col-md-12">
                  <Tab.Container id="left-tabs-example" activeKey={currentTab}>
                    <Nav variant="pills" className="d-none">
                      <Nav.Item>
                        <Nav.Link eventKey={0} disabled={currentTab !== 0}>
                          1
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={1} disabled={currentTab !== 1}>
                          2
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={0} disabled={currentTab !== 0}>
                        <ApplicationCrtUptStep1
                          partners={applications.partners}
                          parkings={applications.parkings}
                          setSelectedOption={setSelectedOption}
                          selectedOption={selectedOption}
                          setSelectedPartner={setSelectedPartner}
                          selectedPartner={selectedPartner}
                          setDuplicateApplicationData={setDuplicateApplicationData}
                          duplicateApplicationData={duplicateApplicationData}
                          setReplace={setReplace}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={1} disabled={currentTab !== 1}>
                        <ApplicationCrtUptStep2
                          colors={selectColor(applications.colors)}
                          tab={currentTab}
                          selectedOption={selectedOption}
                          selectedPartner={selectedPartner}
                          application={applications}
                          setDuplicateApplicationData={setDuplicateApplicationData}
                          duplicateApplicationData={duplicateApplicationData}
                          getNextStepData={getNextStepData}
                          setReplace={setReplace}
                          replace={replace}
                          carTypesList={applications.carTypes}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default ApplicationCrtUpt;
